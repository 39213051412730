import React, { memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import styles from './styles';
import _ from 'lodash';
const useStyles = makeStyles(styles);

const CustomTable = ({
  headers,
  contents,
  type = 'table',
  leftText = { 0: true },
  style = {},
  selectedRow = [],
}) => {
  const classes = useStyles();
  let titleClass = classes.tableTitle;
  let valueClass = classes.tableValue;
  if (type == 'outlined') {
    titleClass = classes.outlinedTitle;
    valueClass = classes.outlinedValue;
  }
  if (type == 'column') {
    titleClass = classes.columnTitle;
    valueClass = classes.columnValue;
  }

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            {headers.map((header, index) => {
              return (
                <TableCell
                  key={index}
                  align={leftText[index] == true ? 'left' : 'center'}
                  className={`${classes.title} ${titleClass}`}
                  style={{ ...style }}
                >
                  {header}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {_.map(contents, (row, index) => (
            <TableRow key={index}>
              {row &&
                row.map((r, rIndex) => (
                  <TableCell
                    key={rIndex}
                    align={leftText[rIndex] == true ? 'left' : 'center'}
                    className={`${classes.value} ${valueClass} ${
                      selectedRow.includes(index) ? classes.selected : ''
                    }`}
                    style={{ ...style }}
                  >
                    {r}
                  </TableCell>
                ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default memo(CustomTable);

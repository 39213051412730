import React from "react";
import { useTranslation } from "react-i18next";
import Hidden from "@material-ui/core/Hidden";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Button from "components/CustomButtons/Button.js";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import styled from "styled-components";
import { Link } from "react-router-dom";
import ConnectWallet from "components/ConnectWallet/ConnectWallet";

const MenuItems = ({
  handleDrawerToggle,
  style,
  footer = false,
  color = "white",
}) => {
  const { t } = useTranslation();
  let currentLocation = window.location.hash;

  return (
    <div style={style}>
      <Hidden mdUp>
        <ListItem
          button
          component={Link}
          to="/"
          onClick={handleDrawerToggle}
          style={{ marginTop: 20 }}
        >
          <ListItemText primary={t("My Adventurer")} />
        </ListItem>
        <ListItem
          button
          component={Link}
          to="/app/claim"
          onClick={handleDrawerToggle}
          style={{ marginTop: 20 }}
        >
          <ListItemText primary={t("Claim")} />
        </ListItem>
        <ListItem
          button
          component={Link}
          to="/app/stake"
          onClick={handleDrawerToggle}
          style={{ marginTop: 20 }}
        >
          <ListItemText primary={t("Liquidity & Staking")} />
        </ListItem>
        <ListItem
          button
          component={Link}
          to="/app/game"
          onClick={handleDrawerToggle}
          style={{ marginTop: 20 }}
        >
          <ListItemText primary={t("Game")} />
        </ListItem>
        <ListItem
          button
          component={Link}
          to="/app/quest"
          onClick={handleDrawerToggle}
          style={{ marginTop: 20 }}
        >
          <ListItemText primary={t("Quest")} />
        </ListItem>
        <ListItem
          button
          component={Link}
          to="/app/about"
          onClick={handleDrawerToggle}
          style={{ marginTop: 20 }}
        >
          <ListItemText primary={t("About")} />
        </ListItem>
        <ConnectWallet />
      </Hidden>
      <Hidden smDown>
        <div>
          <Box
            display="flex"
            justifyContent={"flex-start"}
            alignItems={"center"}
            style={{ marginLeft: "-16px" }}
          >
            <LinkHref
              color={color}
              style={{ padding: 0, paddingRight: footer ? 32 : 0 }}
              title={t("My Adventurer")}
              selected={currentLocation=='#/app'}
              href="/app"
            />
            <LinkHref
              color={color}
              style={{ padding: 0, paddingRight: footer ? 32 : 0 }}
              title={t("Claim")}
              selected={currentLocation=='#/app/claim'}
              href="/app/claim"
            />
            <LinkHref
              color={color}
              style={{ padding: 0, paddingRight: footer ? 32 : 0 }}
              title={t("Liquidity & Staking")}
              selected={currentLocation=='#/app/stake'}
              href="/app/stake"
            />
            <LinkHref
              color={color}
              style={{ padding: 0, paddingRight: footer ? 32 : 0 }}
              title={t("Game")}
              href="/app/game"
            />
            <LinkHref
              color={color}
              style={{ padding: 0, paddingRight: footer ? 32 : 0 }}
              title={t("Quest")}
              href="/app/quest"
            />
            <LinkHref
              color={color}
              style={{ padding: 0, paddingRight: footer ? 32 : 0 }}
              title={t("About")}
              selected={currentLocation=='#/app/about'}
              href="/app/about"
            />

            {/* <LinkHref
              color={"	#808080"}
              style={{
                padding: 0,
                paddingRight: footer ? 32 : 0,
                marginRight: 30,
                pointerEvents: "none",
              }}
              title={t("Battle")}
            /> */}
            <ConnectWallet />
          </Box>
        </div>
      </Hidden>
    </div>
  );
};
const Href = styled.a`
  a:hover {
    text-decoration: none;
  }
`;
const ContainerDiv = styled.div`
  padding-left: 15px;
  a:hover {
    text-decoration: none;
  }
`;
const StyledDiv = styled.div`
  padding: 16px;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: ${(props) => props.color};
  :hover {
    text-decoration: underline;
  }
`;
function LinkHref({ style, title, href, color, selected }) {
  return (
    <ContainerDiv style={style}>
      <Link to={href} style={{ textDecoration: "none" }}>
        <StyledDiv color={color} style={{textDecoration: selected?'underline':'none'}}>{title}</StyledDiv>
      </Link>
    </ContainerDiv>
  );
}
function LinkButton({ style, title, href, color }) {
  return (
    <ContainerDiv style={style}>
      <Link to={href} style={{ textDecoration: "none" }}>
        <Button color="greenOutlined">{title}</Button>
      </Link>
    </ContainerDiv>
  );
}

export default MenuItems;

import React, { useEffect, useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Tooltip from '@material-ui/core/Tooltip';
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useConnectWallet } from "features/home/redux/hooks";
import _ from "lodash";
import Button from "components/CustomButtons/Button.js";
import { useFetchNFT } from "./redux/fetchNFT";

import AvatarBg from "assets/img/equipments/adventurer_bg.PNG";

const equipmentMap = {
  strength: [
    {
      range: [0, 40],
      data: {
        0: "4922_b",
        1: "4929_b",
        2: "5339_b",
        3: "5503_b",
        4: "4808_b",
        5: "4812_b",
        6: "4829_b",
        7: "5395_b",
        8: "5475_b",
        9: "4884_b",
      },
    },
    {
      range: [40, 60],
      data: {
        0: "4781_b",
        1: "4850_b",
        2: "5413_b",
        3: "5545_b",
        4: "4860_b",
        5: "5019_b",
        6: "4858_b",
        7: "5514_b",
        8: "5474_b",
        9: "4923_b",
      },
    },
    {
      range: [60, 80],
      data: {
        0: "4970_b",
        1: "4902_b",
        2: "5546_b",
        3: "5870_b",
        4: "4815_b",
        5: "4941_b",
        6: "5047_b",
        7: "5521_b",
        8: "5471_b",
        9: "4916_b",
      },
    },
    {
      range: [80, 100],
      data: {
        0: "4770_b",
        1: "4897_b",
        2: "5502_b",
        3: "5415_b",
        4: "4813_b",
        5: "4926_b",
        6: "5000_b",
        7: "5525_b",
        8: "5449_b",
        9: "4900_b",
      },
    },
    {
      range: [101, 120],
      data: {
        0: "4763_b",
        1: "4805_b",
        2: "4806_b",
        3: "4766_b",
        4: "4769_b",
        5: "4768_b",
        6: "5006_b",
        7: "5593_b",
        8: "5558_b",
        9: "4931_b",
      },
    },
    {
      range: [120, 140],
      data: {
        0: "4771_b",
        1: "5245_b",
        2: "5414_b",
        3: "4765_b",
        4: "4774_b",
        5: "4908_b",
        6: "5016_b",
        7: "5344_b",
        8: "4767_b",
        9: "4879_b",
      },
    },
    {
      range: [140, 160],
      data: {
        0: "4773_b",
        1: "4896_b",
        2: "5410_b",
        3: "4764_b",
        4: "5159_b",
        5: "5046_b",
        6: "4809_b",
        7: "5350_b",
        8: "5346_b",
        9: "4932_b",
      },
    },
  ],
  agility: [
    {
      range: [0, 20],
      data: {
        0: "9875_b",
        1: "9946_b",
      },
    },
    {
      range: [20, 40],
      data: {
        0: "9706_b",
        1: "9947_b",
      },
    },
    {
      range: [40, 60],
      data: {
        0: "9708_b",
        1: "9711_b",
      },
    },
    {
      range: [60, 80],
      data: {
        0: "9712_b",
        1: "9707_b",
      },
    },
    {
      range: [80, 100],
      data: {
        0: "6794_b",
        1: "7219_b",
      },
    },
    {
      range: [100, 120],
      data: {
        0: "9712_b",
        1: "9710_b",
      },
    },
    {
      range: [120, 140],
      data: {
        0: "6791_b",
        1: "9713_b",
      },
    },
  ],
  intelligence: [
    {
      range: [0, 20],
      data: {
        0: "9660_b",
      },
    },
    {
      range: [20, 40],
      data: {
        0: "4218_b",
      },
    },
    {
      range: [40, 60],
      data: {
        0: "4206_b",
      },
    },
    {
      range: [60, 80],
      data: {
        0: "4336_b",
      },
    },
    {
      range: [80, 100],
      data: {
        0: "4451_b",
      },
    },
    {
      range: [100, 120],
      data: {
        0: "6078_b",
      },
    },
    {
      range: [120, 140],
      data: {
        0: "6181_b",
      },
    },
  ],
  wisdom: [
    {
      range: [0, 20],
      data: {
        0: "6047_b",
        1: "6003_b",
        2: "6121_b",
      },
    },
    {
      range: [20, 40],
      data: {
        0: "6069_b",
        1: "5984_b",
        2: "6145_b",
      },
    },
    {
      range: [40, 60],
      data: {
        0: "6081_b",
        1: "5971_b",
        2: "6158_b",
      },
    },
    {
      range: [60, 80],
      data: {
        0: "6110_b",
        1: "5934_b",
        2: "5981_b",
      },
    },
    {
      range: [80, 100],
      data: {
        0: "6153_b",
        1: "6041_b",
        2: "6001_b",
      },
    },
    {
      range: [100, 120],
      data: {
        0: "6161_b",
        1: "6166_b",
        2: "6103_b",
      },
    },
    {
      range: [120, 140],
      data: {
        0: "6160_b",
        1: "6010_b",
        2: "6157_b",
      },
    },
  ],
  constitution: [
    {
      range: [0, 20],
      data: {
        0: "6038_b",
      },
    },
    {
      range: [20, 40],
      data: {
        0: "6068_b",
      },
    },
    {
      range: [40, 60],
      data: {
        0: "6037_b",
      },
    },
    {
      range: [60, 80],
      data: {
        0: "6083_b",
      },
    },
    {
      range: [80, 100],
      data: {
        0: "6093_b",
      },
    },
    {
      range: [100, 120],
      data: {
        0: "6144_b",
      },
    },
    {
      range: [120, 140],
      data: {
        0: "5936_b",
      },
    },
  ],
  race: {
    "Demi-God": "God_trans",
    Demonseed: "dragon",
    Archon: "a_008",
    Vampire: "a_003",
    Lycanis: "a_018",

    "Star-Elf": "a_065",
    "Stone-Giant": "a_045",
    Shade: "a_096",
    "Hill-Giant": "a_046",
    Centaur: "a_067",

    Entvir: "a_004",
    Minotaur: "a_084",
    "Moon-Elf": "a_071",
    Faerie: "a_082",
    "Shadow-Fae": "a_007",

    Hauflin: "a_086",
    Ogre: "a_041",
    "Swamp-Troll": "a_062",
    "Half-Elf": "a_015",
    Undead: "a_009",

    Gnome: "a_035",
    "Deep-Dwarf": "a_040",
    "Half-Orc": "a_070",
    "Dark-Elf": "a_100",
    Barbarian: "a_079",

    Orc: "a_020",
    Goblin: "a_037",

    HumanM: "a_048",
    HumanF: "a_012",
    "Wood-ElfM": "a_080",
    "Wood-ElfF": "a_094",
  },
  special: {
    Ancient: "sk_r_040",
    DeathBringer: "sk_r_046",
    Doomsayer: "sk_r_044",
    "Heart-Seeker": "sk_r_011",
    "Keeper of Light": "sk_r_015",
    Messiah: "sk_r_065",
    Peon: "sk_r_053",
    Storyteller: "sk_r_098",
    Tempest: "sk_r_011",
    WorldBreaker: "sk_r_012",
    "F u R Y": "sk_r_084",
    Juggernaut: "sk_r_033",
    Nameless: "sk_r_048",
    "Demi-God": "sk_r_009",
    Demonseed0: "sk_r_049",
    Demonseed1: "sk_r_007",
    Demonseed2: "sk_r_061",
    Archon0: "sk_r_026",
    Archon1: "sk_r_024",
    Archon2: "sk_r_042",
  },
};

const useStyles = makeStyles((theme) => ({
  root: {},
  card: {
    flexGrow: 1,
    backgroundColor: "transparent",
    borderBottom: `2px solid ${theme.palette.text.primary}`,
    overflow: "hidden",
    marginTop: 10,
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  title: {
    color: theme.palette.text.primary,
    textAlign: "left",
    fontSize: "1.8rem",
    paddingTop: 15,
  },
  subTitle: {
    textAlign: "left",
    fontSize: "1.1rem",
    marginTop: 10,
    color: theme.palette.text.primary,
  },
  metricsTitle: {
    fontSize: "0.8rem",
    marginTop: 18,
    marginBottom: 8,
    fontWeight: 600,
    color: theme.palette.text.secondary,
  },
  metricsValue: {
    fontSize: "0.9rem",
    fontWeight: 700,
    color: theme.palette.text.primary,
  },
  cardTitle: {
    textAlign: "center",
    fontSize: "1.3rem",
    color: theme.palette.text.primary,
  },
  cardValue: {
    fontSize: 17,
    fontWeight: 600,
    marginBottom: 10,
    color: theme.palette.text.primary,
    fontFamily: "Superclarendon",
    textAlign: "left",
  },

  bigValue: {
    textAlign: "center",
    fontSize: "2rem",
    color: theme.palette.text.primary,
  },
  label: {
    color: theme.palette.text.primary,
    fontFamily: "Superclarendon",
    fontSize: "1.15rem",
    fontWeight: "800",
  },
  lgIcon: {
    width: 140,
    height: 140,
    objectFit: "contain",
  },
  icon: {
    width: 48,
    height: 48,
    objectFit: "contain",
  },
  outerIcon: {
    width: 58,
    height: 58,
    objectFit: "contain",
  },
  outerLgIcon: {
    width: 160,
    height: 160,
    objectFit: "contain",
  },
}));

export default function Dashboard() {
  const classes = useStyles();
  const theme = useTheme();
  const { web3, address } = useConnectWallet();

  const { nftsData, fetchNFT } = useFetchNFT();

  useEffect(() => {
    if (address) {
      fetchNFT({ web3, address });
      const id = setInterval(() => {
        fetchNFT({ web3, address });
      }, 30000);
      return () => clearInterval(id);
    }
  }, [address]);

  const renderAttrs = (data) => {
    return data.attrs.map((attr) => {
      return (
        <>
          <div className={classes.cardValue}>
            <span style={{ color: "#b4b4b4" }}>
              {_.capitalize(attr.trait_type)}:{" "}
            </span>
            {attr.value}
          </div>
        </>
      );
    });
  };

  const renderItem = (url, path, isSpecial = false) => {
    let normal_image_path = '';
        try {
            normal_image_path = require(`assets/img/equipments/${path}/${url}.PNG`);
        } catch(err){
            normal_image_path = require('assets/img/equipments/normal_empty.PNG');  //set default image path
        }
    return (
      <Tooltip title={"Your adventurer’s ability, spells, and item’s additional power will be displayed soon. Stay tune for more updates!"}>
      <span style={{ position: "relative", marginRight: 1 }}>
        <img
          src={require(`assets/img/equipments/${
            isSpecial ? "special_empty" : "normal_empty"
          }.PNG`)}
          className={classes.outerIcon}
          onError={(e) => (e.target.onerror = null, e.target.src = "assets/img/equipments/normal_empty.PNG")}
        />
        {url && (
          <img
            src={normal_image_path}
            className={classes.icon}
            style={{ position: "absolute", left: "3.5px", marginTop: 3.5 }}
            onError={(e) => (e.target.onerror = null, e.target.src = "assets/img/equipments/normal_empty.PNG")}
          />
        )}
      </span>
      </Tooltip>
    );
  };

  const renderImages = (data) => {
    const strength = parseInt(
      _.find(data.attrs, { trait_type: "strength" }).value
    );
    const agility = parseInt(
      _.find(data.attrs, { trait_type: "agility" }).value
    );
    const intelligence = parseInt(
      _.find(data.attrs, { trait_type: "intelligence" }).value
    );
    const constitution = parseInt(
      _.find(data.attrs, { trait_type: "constitution" }).value
    );

    let classType = _.find(data.attrs, { trait_type: "class" }).value;
    let gender = _.find(data.attrs, { trait_type: "gender" }).value;
    let race = _.find(data.attrs, { trait_type: "race" }).value;
    if (race == "Human" || race == "Wood-Elf") {
      if (gender == "Male") race += "M";
      if (gender == "Female") race += "F";
    }
    if (classType == "Demonseed" || classType == "Demonseed") {
      classType += parseInt(data.tokenId) % 3;
    }
    const specialClass = equipmentMap.special[classType];
    const wisdom = parseInt(_.find(data.attrs, { trait_type: "wisdom" }).value);
    const isRare = race == "Demi-god" || race == "Demonseed";

    let normal_race_path = '';
        try {
            normal_race_path = require(`assets/img/equipments/races/${equipmentMap.race[race]}.PNG`);
        } catch(err){
            normal_race_path = require('assets/img/equipments/normal_empty.PNG');  //set default image path
        }

    return (
      <Grid container spacing={0} alignItems="center" style={{}}>
        <Grid item xs={5}>
          <div
            style={{
              position: "relative",
              marginTop: 10,
              marginLeft: 45,
            }}
          >
            <img
              src={require(`assets/img/equipments/${
                isRare ? "avatar_empty" : "avatar_empty"
              }.PNG`)}
              className={classes.outerLgIcon}
              onError={(e) => (e.target.onerror = null, e.target.src = "assets/img/equipments/normal_empty.PNG")}
            />
            <img
              src={normal_race_path}
              className={classes.lgIcon}
              style={{
                position: "absolute",
                left: "10px",
                top: 0,
                marginTop: 10,
              }}
              onError={(e) => (e.target.onerror = null, e.target.src = "assets/img/equipments/races/avatar_empty.PNG")}
            />
          </div>
          <div className={classes.cardTitle}>
            {_.find(data.attrs, { trait_type: "race" }).value}
          </div>
        </Grid>
        <Grid item xs={7} style={{ paddingLeft: 0 }}>
          <div style={{ marginBottom: 10 }}>
            {renderItem(specialClass, "special", true)}
          </div>
          <div>
            {renderItem(
              _.find(equipmentMap.strength, function (o) {
                return _.inRange(strength, o.range[0], o.range[1]);
              }).data[parseInt(strength % 10)],
              "strength"
            )}
            {renderItem(
              _.find(equipmentMap.agility, function (o) {
                return _.inRange(agility, o.range[0], o.range[1]);
              }).data[parseInt(agility % 2)],
              "agility"
            )}
            {renderItem(
              _.find(equipmentMap.intelligence, function (o) {
                return _.inRange(intelligence, o.range[0], o.range[1]);
              }).data[0],
              "intelligence"
            )}
            {renderItem(
              _.find(equipmentMap.wisdom, function (o) {
                return _.inRange(wisdom, o.range[0], o.range[1]);
              }).data[parseInt(wisdom % 3)],
              "wisdom"
            )}
            {renderItem(
              _.find(equipmentMap.constitution, function (o) {
                return _.inRange(constitution, o.range[0], o.range[1]);
              }).data[0],
              "constitution"
            )}
          </div>
        </Grid>
      </Grid>
    );
  };
  const renderNFTCollections = () => {
    // console.log("nftsData", nftsData);
    if (!nftsData) {
      return <div>Loading...</div>;
    } else if (nftsData.length == 0) {
      return <h3 className={classes.subTitle}>No Items</h3>;
    }
    return nftsData.map((data, index) => {
      return (
        <Grid item xs={12} md={12} key={index}>
          <div className={classes.card} style={{ padding: "30px 0" }}>
            <Grid container spacing={3} alignItems="center">
              <Grid item xs={12} md={4}>
                <div style={{ fontSize: 15 }}>
                  {index + 1}
                  <span style={{ float: "right" }}>#{data.tokenId}</span>
                </div>
                <img
                  src={"https://ipfs.infura.io/ipfs/" + data.imgCid}
                  style={{
                    width: "100%",
                    marginTop: 10,
                    objectFit: "contain",
                    border: "1px solid white",
                  }}
                  alt=""
                />
              </Grid>
              <Grid item xs={12} md={2} style={{ paddingTop: 40 }}>
                {renderAttrs(data)}
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                style={{
                  marginTop: 20,
                  padding: "80px 0",
                  backgroundImage: `url(${AvatarBg})`,
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                }}
              >
                {renderImages(data)}
              </Grid>
            </Grid>
          </div>
        </Grid>
      );
    });
  };

  return (
    <div>
      <h1 className={classes.title}>My Adventurer</h1>
      <h3 className={classes.subTitle}>
        Get ready for the MGG adventurers !!!
        <br /> Every HOE will generate an unique MGG adventurer that the data
        and components are determined by HOE’s traits. More details will be
        released soon!
      </h3>
      {renderNFTCollections()}
      <div style={{marginLeft:'auto',marginRight:'auto',textAlign:'center'}}>
        <Button as={"a"} href="https://opensea.io/collection/heroes-of-evermore" target="_blank" style={{border:'white 1px solid'}}>Get your HOE on Opensea now.</Button>
      </div>
    </div>
  );
}

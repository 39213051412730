import React, { useEffect } from "react";
import FarmRow from "./components/FarmRow";
import { makeStyles } from "@material-ui/core/styles";
import { contracts, tokens } from "features/configure";
import {
  useFetchPools,
  useFetchStake,
  useFetchUnstake,
  useFetchClaim,
  useFetchStakeMGG,
  useFetchUnstakeMGG,
  useFetchClaimStakeMGG,
} from "features/stake/redux/hooks";
import { useConnectWallet } from "features/home/redux/hooks";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "22.00%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

export default function Farms() {
  const { web3, address } = useConnectWallet();
  const { fetchStakeMGG, fetchStakeMGGPending } = useFetchStakeMGG();
  const { fetchUnstakeMGG, fetchUnstakeMGGPending } = useFetchUnstakeMGG();
  const { fetchClaimStakeMGG, fetchClaimStakeMGGPending } =
    useFetchClaimStakeMGG();
  const { detail, fetchPools } = useFetchPools();

  const { fetchStake, fetchStakePending } = useFetchStake();
  const { fetchUnstake, fetchUnstakePending } = useFetchUnstake();
  const { fetchClaim, fetchClaimStakePending } = useFetchClaim();
  useEffect(() => {
    if (web3 && address) {
      fetchPools();
      const id = setInterval(() => {
        fetchPools();
      }, 10000);
      return () => clearInterval(id);
    }
  }, [web3, address]);
  const pools = [
    {
      token: tokens.slp,
      deposited: detail.slpStaked,
      apy: detail.slpAPY,
      tvl: parseFloat(
        parseFloat(detail.slpTotalStaked) * parseFloat(detail.lpPrice)
      ).toFixed(0),
      displayDecimals: 8,
      wallet: detail.slpBalance,
      decimals: 18,
      allowance: detail.slpAllowance,
      earn: detail.stakeSLPRewards,
      onStake: (amount) => {
        fetchStake({ web3, address, amount, pid: 0 });
      },
      onUnstake: (amount) => {
        fetchUnstake({ web3, address, amount, pid: 0 });
      },
      onClaim: () => {
        fetchClaim({ web3, address, pid: 0 });
      },
      contract: contracts.stakingPools.address,
    },
    {
      token: tokens.mgg,
      deposited: detail.mggStaked,
      apy: detail.mggAPY,
      tvl: parseFloat(
        parseFloat(detail.mggTotalStaked) * parseFloat(detail.mggPrice)
      ).toFixed(0),
      displayDecimals: 2,
      wallet: detail.mggBalance,
      decimals: 18,
      allowance: detail.mggAllowance,
      onStake: (amount) => {
        fetchStakeMGG({ web3, address, amount });
      },
      onUnstake: (amount) => {
        fetchUnstakeMGG({ web3, address, amount });
      },
      onClaim: () => {
        fetchClaimStakeMGG({ web3, address });
      },
      earn: detail.stakeMGGRewards,
      contract: contracts.mggStaking.address,
    },
  ];
  const classes = useStyles();

  const [active, setActive] = React.useState(true);
  const handleChangeActive = (event, newAlignment) => {
    if (newAlignment !== null) {
      setActive(newAlignment);
    }
  };

  let utcTime = "2021-09-11 02:30:00";
  var local_date = moment.utc(utcTime).local().format("YYYY-MM-DD HH:mm Z");
  return (
    <>
      <h1 className="header">Staking</h1>

      <div>
        <br />
        {pools.map((pool, index) => (
          <div style={{ marginBottom: 10 }}>
            <FarmRow detail={pool} key={index} />
          </div>
        ))}
      </div>
    </>
  );
}

import initialState from "./initialState";
import { reducer as fetchBidDetailReducer } from "./fetchBidDetail";
import { reducer as fetchDoBidReducer } from "./fetchDoBid";
import { reducer as fetchDoCloseAuctionReducer } from "./fetchDoCloseAuction";

const reducers = [fetchBidDetailReducer, fetchDoBidReducer, fetchDoCloseAuctionReducer];

export default function reducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    // Handle cross-topic actions here
    default:
      newState = state;
      break;
  }
  /* istanbul ignore next */
  return reducers.reduce((s, r) => r(s, action), newState);
}

import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useConnectWallet } from "features/home/redux/hooks";
import BigNumber from "bignumber.js";
import CustomOutlinedInput from "components/CustomOutlinedInput/CustomOutlinedInput";
import ButtonBase from "@material-ui/core/ButtonBase";
import Button from "components/CustomButtons/Button.js";
import { useFetchBidDetail, useFetchDoBid, useFetchDoCloseAuction} from "features/bid/redux/hooks";
import { inputLimitPass, inputFinalVal } from "features/helpers/utils";
import { formatCountdown } from "features/helpers/format";
import moment from "moment";
import _ from "lodash";
import { convertAmountFromNumber, convertAmountFromRawNumber } from "features/helpers/bignumber";

const useStyles = makeStyles((theme) => ({
  subTitle: {
    color: "gray",
    fontSize: 18,
    marginLeft: 20,
  },
  timeSpan: {
    color: "gray",
    fontSize: 22,
    marginLeft: 5,
    marginRight: "15px",
  },
  xlIcon: {
    height: 400,
    width: "100%",
    objectFit: "contain",
  },
  banner: {
    width: "100%",
    maxWidth: 350,
    height: 60,
    objectFit: "contain",
  },
  bidNumber: {
    margin: "30px 0",
    fontSize: 50,
  },
  imageButtonBase: {
    width: 300,
    margin: 30,
    position: "relative",
    height: 100,
  },
  imageButton: {
    position: "absolute",
    left: 0,
    top: 0,
    width: 300,
    height: 100,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  imageSrc: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: "cover",
  },
  card: {
    flexGrow: 1,
    backgroundColor: "rgba(0,0,0,0.5)",
    overflow: "hidden",
    borderRadius: 10,
    margin: "0 auto",
    marginTop: 10,
    padding: "10px 20px",
    maxWidth: 600,
    fontSize: 26,
  },
}));

export default function BidPage() {
  const [depositBalance, setDepositBalance] = useState("");
  const [toggled, setToggled] = React.useState(false);
  const { web3, address } = useConnectWallet();
  const { fetchDoBid, fetchDoBidPending } = useFetchDoBid();
  const { fetchDoCloseAuction, fetchDoCloseAuctionPending } = useFetchDoCloseAuction();
  const { fetchBidDetail, fetchBidDetailPending, detail } = useFetchBidDetail();
  const classes = useStyles();

  useEffect(() => {
    if (web3 && address) {
      fetchBidDetail({ web3, address });
      const id = setInterval(() => {
        fetchBidDetail({ web3, address });
      }, 10000);
      return () => clearInterval(id);
    }
  }, [web3, address]);

  useEffect(() => {
    setToggled(fetchDoBidPending);
  }, [fetchDoBidPending]);

  const setDepositMaximumInput = () => {
    const total = detail.wallet;
    if (total > 0) {
      const amount = new BigNumber(total).toFormat();
      setDepositBalance(amount.replaceAll(",", ""));
    }
  };
  const changeDepositInputValue = (event) => {
    let value = event.target.value;
    const total = detail.wallet;
    if (!inputLimitPass(value)) {
      return;
    }

    setDepositBalance(inputFinalVal(value, total));
  };

  const doBid = (tokenId) => {
    console.log('dobid', tokenId, depositBalance, fetchDoBidPending)
    if (tokenId < 0 || !depositBalance || fetchDoBidPending) return;

    fetchDoBid({
      address,
      web3,
      tokenId: tokenId,
      amount: convertAmountFromNumber(depositBalance),
    }).then(() => {});
  };

  const doCloseAuction = () => {
    fetchDoCloseAuction({
      address,
      web3,
    }).then(() => {});
  };


  const auction = detail.auction;
  const tokenId = detail.auction ? parseFloat(detail.auction[0]) : undefined;
  const currentBid = detail.auction ? parseFloat(convertAmountFromRawNumber(detail.auction[1])) : '-';
  const endTime = detail.auction ? parseFloat(detail.auction[3]) * 1000 : '-'
  const formatDate = formatCountdown(endTime);

  // console.log('endTime', endTime)
  // console.log('formatDate', formatDate)

  const currentBidder = detail.auction ? detail.auction[4] : 'Loading...';
  const isSettled = detail.auction ? detail.auction[5] : false;
  const minBidIncrementPercentage = parseFloat(detail.minBidIncrementPercentage)
  const nextMinBid = currentBid > 0 && minBidIncrementPercentage >=0 ? (currentBid + ((currentBid * minBidIncrementPercentage) / 100)).toFixed(2) : '-'
  const reservePrice = detail.reservePrice ? detail.reservePrice : undefined;
  const nftImageUrl = detail.imageUrl ? detail.imageUrl : require("assets/img/landing/badge10.png");
  const attrs = detail.attrs ? detail.attrs: [];

  let pastBidders = detail.bids ? detail.bids : [];
  pastBidders = _.map(pastBidders, function(n) {
    return {
      address: n.bidder.id,
      bidBalance: convertAmountFromRawNumber(parseFloat(n.amount)),
      txn: n.id
    }
  });

  let footerText = reservePrice > 0 ? `Reserve price: ${reservePrice} ETH` : ``;

  if(currentBid > 0) {
    footerText = currentBid > 0 ? `Next minimum bid: ${nextMinBid} ETH` : ``;
  }

  const [auctionEnded, setAuctionEnded] = useState(false);
  const [auctionTimer, setAuctionTimer] = useState(false);

  useEffect(() => {
    if (!auction) return;

    // const now = Math.floor(Date.now())
    // const timeLeft = Number(endTime) - now;

    if (auction && formatDate == '00:00:00') {
      setAuctionEnded(true);
    } else {
      setAuctionEnded(false);
      const timer = setTimeout(() => {
        setAuctionTimer(!auctionTimer);
      }, 1000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [auctionTimer, auction]);

  return (
    <>
    <div style={{top:'20vh',left:'20vw',position: 'fixed',height: '60vh',width: '60vw',background: 'rgba(0, 0, 0, 0.8)',zIndex:10, textAlign:'center',verticalAlign:'middle',lineHeight:'60vh',fontSize:36, borderRadius:30}}>
      Guild event coming soon
    </div>
      <h1 className="header">
        Guild
        <span className={classes.subTitle}>Be the owner of the guild.</span>
      </h1>
      <Grid container alignItems="center" style={{ textAlign: "center" }}>
        <Grid item xs={6} md={6}>
          <img
            className={classes.xlIcon}
            src={nftImageUrl}
          />
        </Grid>
        <Grid item xs={6} md={6}>
        <img
            className={classes.banner}
            src={require("assets/img/banner1.png")}
          />
          <div style={{ margin: "30px 0" }}>
            {renderAttrs(attrs)}
          </div>
          <img
            className={classes.banner}
            src={require("assets/img/banner2.png")}
          />
        </Grid>
        <Grid item xs={6} md={6}>
          <h1>Current Bid</h1>
          <div className={classes.bidNumber}>Ξ -</div>
        </Grid>
        <Grid item xs={6} md={6}>
          <h1>Ends in</h1>
          <div className={classes.bidNumber}>
            {formatDate.day ? formatDate.day : 0}
            <span className={classes.timeSpan}>days </span>
            {formatDate.hours ? formatDate.hours : 0}
            <span className={classes.timeSpan}>h</span>
            {formatDate.minutes ? formatDate.minutes : 0}
            <span className={classes.timeSpan}>m</span>
            {formatDate.seconds ? formatDate.seconds : 0}
            <span className={classes.timeSpan}>s</span>
          </div>
        </Grid>

        { !auctionEnded ? (
          <Grid item xs={12}>
            <div style={{ maxWidth: 600, margin: "0 auto" }}>
              <CustomOutlinedInput
                value={depositBalance}
                onClick={() => {}}
                footerText={footerText}
                availabletext={`Wallet: ${parseFloat(detail.wallet).toFixed(
                  6
                )} ETH`}
                onChange={changeDepositInputValue}
                setMax={() => setDepositMaximumInput()}
              />

            </div>
          </Grid>
        ) : (<></>)
        }


        <Grid item xs={12}>
          {renderBtn({isSettled, auctionEnded, classes, tokenId, doBid, doCloseAuction, toggled})}
        </Grid>
      </Grid>
      <div style={{ marginTop: 50 }}>
        {pastBidders.map((data, index) => {
          return (
            <div className={classes.card} key={index}>
              <Grid container>
                <Grid item xs={9}>
                  <a target="_blank" href={`https://etherscan.io/tx/${data.txn}`}>
                    {`${data.address.slice(0, 8)}...`}
                  </a>
                </Grid>
                <Grid item xs={3}>
                  Ξ {data.bidBalance}
                </Grid>
              </Grid>
            </div>
          );
        })}
      </div>
    </>
  );
}

const renderBtn = ({isSettled, auctionEnded, classes, tokenId, doBid, doCloseAuction, toggled}) => {
  if(!isSettled) {
    if(auctionEnded) {
      return (
        <ButtonBase
              disableRipple
              className={classes.imageButtonBase}
              onClick={() => {
                doCloseAuction();
              }}
            >
              <h1 style={{ zIndex: 100, fontSize: 20 }}>Close Auction</h1>
              <img
                className={classes.imageButton}
                src={require(toggled
                  ? "assets/img/btn2.png"
                  : "assets/img/btn1.png")}
              />
        </ButtonBase>
      )
    }
    else {
      return (
        <ButtonBase
              disableRipple
              className={classes.imageButtonBase}
              onClick={() => {
                doBid(tokenId);
              }}
            >
              <h1 style={{ zIndex: 100, fontSize: 40 }}>Bid</h1>
              <img
                className={classes.imageButton}
                src={require(toggled
                  ? "assets/img/btn2.png"
                  : "assets/img/btn1.png")}
              />
        </ButtonBase>
      )
    }
  }
}
const renderAttrs = (attrs) => {
  return attrs.map((attr) => {
    return (
      <h2>{attr.value}</h2>
    );
  });
};

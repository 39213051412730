import { useCallback } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {
  POOLS_FETCH_BEGIN,
  POOLS_FETCH_SUCCESS,
  POOLS_FETCH_FAILURE,
} from "./constants";
import { MultiCall } from "eth-multicall";
import {
  erc20ABI,
  liquidityHandlerABI,
  tokens,
  contracts,
  stakingPoolsABI,
  MGGStakingABI,
} from "../../configure";
import BigNumber from "bignumber.js";
import { convertAmountFromRawNumber } from "../../helpers/bignumber";
const BLOCKS_PER_YEAR = new BigNumber(2368850);
const REWARD_PER_BLOCK = new BigNumber(850000000000000000);
const poolsWeighting = {
  0: 1,
};
export function fetchPools() {
  return (dispatch, getState) => {
    dispatch({
      type: POOLS_FETCH_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      const { home, price } = getState();
      const { address, web3 } = home;
      const { mggPrice, lpPrice } = price.priceData;

      const multicall = new MultiCall(web3, contracts.multicall.address);

      const stakingPoolContract = new web3.eth.Contract(
        stakingPoolsABI,
        contracts.stakingPools.address
      );
      const mggStakingContract = new web3.eth.Contract(
        MGGStakingABI,
        contracts.mggStaking.address
      );
      const mggContract = new web3.eth.Contract(erc20ABI, tokens.mgg.address);
      const slpContract = new web3.eth.Contract(erc20ABI, tokens.slp.address);
      const calls = [
        { result: mggContract.methods.balanceOf(address) }, //0
        { result: slpContract.methods.balanceOf(address) }, //1
        {
          result: mggContract.methods.allowance(
            address,
            contracts.mggStaking.address
          ),
        }, //2
        {
          result: slpContract.methods.allowance(
            address,
            contracts.stakingPools.address
          ), //3
        },
        { result: mggStakingContract.methods.pendingReward(address) }, //4
        { result: mggStakingContract.methods.userDeposited(address) }, //5
        {
          result: stakingPoolContract.methods.getStakeTotalUnclaimed(
            address,
            0
          ),
        }, //6
        {
          result: stakingPoolContract.methods.getStakeTotalDeposited(
            address,
            0
          ),
        }, //7
        {
          result: stakingPoolContract.methods.getPoolTotalDeposited(0),
        }, //8
        { result: mggStakingContract.methods.totalDeposited() }, //9
        { result: mggStakingContract.methods.mggRewardRate() }, //10
        { result: stakingPoolContract.methods.getPoolRewardRate(0) }, //11
      ];

      multicall
        .all([calls])
        .then(([results]) => {
          const mggBalance = convertAmountFromRawNumber(results[0].result);
          const slpBalance = convertAmountFromRawNumber(results[1].result);
          const mggAllowance = convertAmountFromRawNumber(results[2].result);
          const slpAllowance = convertAmountFromRawNumber(results[3].result);
          const stakeMGGRewards = convertAmountFromRawNumber(results[4].result);
          const mggStaked = convertAmountFromRawNumber(results[5].result);
          const stakeSLPRewards = convertAmountFromRawNumber(results[6].result);
          const slpStaked = convertAmountFromRawNumber(results[7].result);
          const slpTotalStaked = new BigNumber(results[8].result);
          const mggTotalStaked = new BigNumber(results[9].result);
          const mggRewardRate = new BigNumber(results[10].result);
          const slpRewardRate = new BigNumber(results[11].result);

          const slpAPY = slpRewardRate
            .times(BLOCKS_PER_YEAR)
            .times(new BigNumber(poolsWeighting[0]))
            .times(mggPrice)
            .div(new BigNumber(slpTotalStaked).times(lpPrice));

          const mggAPY = mggRewardRate
            .times(BLOCKS_PER_YEAR)
            .times(mggPrice)
            .div(mggTotalStaked);
          const output = {
            mggBalance,
            mggAllowance,
            slpAllowance,
            slpBalance,
            stakeMGGRewards,
            mggStaked,
            stakeSLPRewards,
            slpTotalStaked: convertAmountFromRawNumber(slpTotalStaked),
            mggTotalStaked: convertAmountFromRawNumber(mggTotalStaked),
            slpStaked,
            mggPrice,
            lpPrice,
            slpAPY,
            mggAPY,
          };
          console.log(output);
          dispatch({
            type: POOLS_FETCH_SUCCESS,
            data: output,
          });
          resolve();
        })
        .catch((error) => {
          dispatch({
            type: POOLS_FETCH_FAILURE,
          });
          return reject(error.message || error);
        });
    });

    return promise;
  };
}

export function useFetchPools() {
  const dispatch = useDispatch();

  const { detail, fetchPoolsPending, fetchPoolsDone } = useSelector(
    (state) => ({
      detail: state.stake.detail,
      fetchPoolsPending: state.stake.fetchPoolsPending,
      fetchPoolsDone: state.stake.fetchPoolsDone,
    }),
    shallowEqual
  );

  const boundAction = useCallback(
    (data) => {
      return dispatch(fetchPools(data));
    },
    [dispatch]
  );

  return {
    detail,
    fetchPools: boundAction,
    fetchPoolsDone,
    fetchPoolsPending,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case POOLS_FETCH_BEGIN:
      return {
        ...state,
        fetchPoolsPending: true,
      };

    case POOLS_FETCH_SUCCESS:
      return {
        ...state,
        detail: action.data,
        fetchPoolsDone: true,
        fetchPoolsPending: false,
      };

    case POOLS_FETCH_FAILURE:
      return {
        ...state,
        fetchPoolsPending: false,
      };

    default:
      return state;
  }
}

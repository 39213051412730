import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  FETCH_PRICE_BEGIN,
  FETCH_PRICE_SUCCESS,
  FETCH_PRICE_FAILURE,
} from "./constants";
import BigNumber from "bignumber.js";
import { convertAmountFromRawNumber } from "features/helpers/bignumber";
import { erc20ABI, sushiLPABI, contracts, tokens } from "features/configure";

export function fetchPrice({ web3 }) {
  return (dispatch) => {
    dispatch({
      type: FETCH_PRICE_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      const contract = new web3.eth.Contract(erc20ABI, tokens.mgg.address);
      const lpContract = new web3.eth.Contract(sushiLPABI, tokens.slp.address);
      const usdcContract = new web3.eth.Contract(erc20ABI, tokens.usdc.address);
      Promise.all([
        contract.methods.totalSupply().call(),
        lpContract.methods.getReserves().call(),
        lpContract.methods.totalSupply().call(),
        usdcContract.methods.balanceOf(tokens.slp.address).call(),
      ])
        .then((data) => {
          dispatch({
            type: FETCH_PRICE_SUCCESS,
            data,
          });
          resolve(data);
        })
        .catch((error) => {
          dispatch({
            type: FETCH_PRICE_FAILURE,
          });
          reject(error.message || error);
        });
    });
    return promise;
  };
}

export function useFetchPrice() {
  const dispatch = useDispatch();

  const { fetchPricePending, priceData } = useSelector((state) => ({
    fetchPricePending: state.price.fetchPricePending,
    priceData: state.price.priceData,
  }));

  const boundAction = useCallback(
    (data) => {
      return dispatch(fetchPrice(data));
    },
    [dispatch]
  );

  return {
    fetchPrice: boundAction,
    fetchPricePending,
    priceData,
  };
}

export function reducer(
  state = { fetchPricePending: false, priceData: [] },
  action
) {
  switch (action.type) {
    case FETCH_PRICE_BEGIN:
      return {
        ...state,
        fetchPricePending: {
          ...state.fetchPricePending,
        },
      };

    case FETCH_PRICE_SUCCESS:
      const usdcPrice = 1;
      const usdcBalance = new BigNumber(action.data[3]);
      const lpData = { ...action.data[1], totalSupply: action.data[2] };
      const price = parseFloat(
        new BigNumber(convertAmountFromRawNumber(lpData._reserve1, 6))
          .div(new BigNumber(convertAmountFromRawNumber(lpData._reserve0)))
          .toString()
      );
      const circulatingSupply = action.data[0];
      const CMC = circulatingSupply * price;
      const lpTotalSupply =
        lpData && lpData.totalSupply ? lpData.totalSupply : 1;
      const lpPrice =
        usdcBalance && lpTotalSupply
          ? parseFloat(
              usdcBalance.times(2).div(lpTotalSupply).times(1e12).toString()
            )
          : 0;
      return {
        ...state,
        priceData: {
          price,
          usdcPrice,
          mggPrice: price,
          circulatingSupply,
          CMC,
          lpData,
          lpPrice,
          lpTotalSupply,
        },
        fetchPricePending: {
          ...state.fetchPricePending,
        },
      };

    case FETCH_PRICE_FAILURE:
      return {
        ...state,
        fetchPricePending: {
          ...state.fetchPricePending,
        },
      };

    default:
      return state;
  }
}

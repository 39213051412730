import React, { useState } from "react";
import { Grid, Container } from "@material-ui/core";
import CustomOutlinedInput from "components/CustomOutlinedInput/CustomOutlinedInput";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles } from "@material-ui/core/styles";
import Button from "components/CustomButtons/Button.js";
import { inputLimitPass, inputFinalVal } from "features/helpers/utils";
import BigNumber from "bignumber.js";
import { tokens } from "features/configure";
import { useFetchApproval } from "features/stake/redux/hooks";
import { useConnectWallet } from "features/home/redux/hooks";
import { convertAmountFromNumber } from "features/helpers/bignumber";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "22.00%",
    flexShrink: 0,
  },
  poolValue: {
    fontSize: 16,
    fontWeight: 600,
    textAlign: "center",
    marginTop: 7,
  },
  poolHeader: {
    fontSize: 16,
    fontWeight: 600,
    textAlign: "center",
  },
  poolIcon: {
    width: 50,
    height: 50,
    verticalAlign: "middle",
    objectFit: "contain",
    marginRight: 10,
  },
}));

export default function FarmRow({ detail }) {
  const classes = useStyles();
  const decimals = detail.decimals;
  const { web3, address } = useConnectWallet();
  const { fetchApproval } = useFetchApproval();
  const [depositBalance, setDepositBalance] = useState("");
  const [withdrawBalance, setWithdrawBalance] = useState("");
  const setDepositMaximumInput = () => {
    const total = detail.wallet;
    if (total > 0) {
      const amount = new BigNumber(total).toFormat();
      setDepositBalance(amount.replaceAll(",", ""));
    }
  };
  const setWithdrawMaximumInput = () => {
    const total = detail.available;
    if (total > 0) {
      const amount = new BigNumber(total).toFormat();
      setWithdrawBalance(amount.replaceAll(",", ""));
    }
  };
  const changeDepositInputValue = (event) => {
    let value = event.target.value;
    const total = detail.wallet;
    if (!inputLimitPass(value, decimals)) {
      return;
    }

    setDepositBalance(inputFinalVal(value, total, decimals));
  };
  const changeWithdrawInputValue = (event) => {
    let value = event.target.value;
    const total = detail.available;
    if (!inputLimitPass(value, decimals)) {
      return;
    }

    setWithdrawBalance(inputFinalVal(value, total, decimals));
  };

  const onApprove = () => {
    fetchApproval({
      address,
      web3,
      tokenAddress: detail.token.address,
      contractAddress: detail.contract,
    });
  };
  return (
    <div>
      <Accordion style={{ borderRadius: 10 }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Grid item xs={4}>
            <img
              src={require("assets/img/" + detail.token.token + ".png")}
              className={classes.poolIcon}
            />
            <span className="vaultHeader" style={{ verticalAlign: "middle" }}>
              {detail.token.token}
            </span>
          </Grid>
          <Grid item xs={2}>
            <div className={classes.poolHeader}>Deposited</div>
            <div className={classes.poolValue}>
              {isFinite(detail.deposited)
                ? parseFloat(detail.deposited).toFixed(detail.displayDecimals)
                : "Loading..."}
            </div>
          </Grid>

          <Grid item xs={2}>
            <div className={classes.poolHeader}>APY</div>
            <div className={classes.poolValue}>
              {isFinite(detail.apy)
                ? parseFloat(detail.apy * 100).toFixed(2)
                : "Loading..."}
              %{detail.token.token == "MGG" ? " + Guild Tax" : ""}
            </div>
          </Grid>
          <Grid item xs={2}>
            <div className={classes.poolHeader}>TVL</div>
            <div className={classes.poolValue}>
              $
              {isFinite(detail.tvl)
                ? new BigNumber(detail.tvl).toFormat(0)
                : "Loading..."}
            </div>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <CustomOutlinedInput
                value={depositBalance}
                text={"Deposit"}
                onClick={detail.onDeposit}
                availabletext={`Wallet: ${parseFloat(detail.wallet).toFixed(
                  detail.displayDecimals
                )}`}
                onChange={changeDepositInputValue}
                setMax={() => setDepositMaximumInput()}
              />
              {detail.allowance && parseFloat(detail.allowance) > 0 ? (
                <Button
                  color="primary"
                  onClick={() => {
                    detail.onStake(convertAmountFromNumber(depositBalance));
                  }}
                  fullWidth
                >
                  Deposit
                </Button>
              ) : (
                <Button color="primary" onClick={onApprove} fullWidth>
                  Approve
                </Button>
              )}
            </Grid>
            <Grid item xs={12} md={4}>
              <CustomOutlinedInput
                value={withdrawBalance}
                text={"Withdraw"}
                availabletext={`Available: ${parseFloat(
                  detail.deposited
                ).toFixed(detail.displayDecimals)}`}
                onChange={changeWithdrawInputValue}
                setMax={() => setWithdrawMaximumInput()}
              />
              <Button
                color="primary"
                fullWidth
                onClick={() => {
                  detail.onUnstake(convertAmountFromNumber(withdrawBalance));
                }}
              >
                Withdraw
              </Button>
            </Grid>
            <Grid item xs={12} md={4}>
              {detail.addUrl && (
                <a
                  href={detail.addUrl}
                  target="_blank"
                  style={{ fontSize: 18 }}
                >
                  Add Liquidity
                </a>
              )}
              <div style={{ textAlign: "center", marginTop: 52 }}>
                <div className="coloredTitle">Earn MGG</div>
                <div className="value" style={{ fontSize: 45 }}>
                  {parseFloat(detail.earn).toFixed(4)}
                </div>
              </div>
              <Button
                color="primary"
                onClick={detail.onClaim}
                fullWidth
                style={{ marginTop: 17 }}
              >
                Claim
              </Button>
            </Grid>
            <div style={{ width: "100%", textAlign: "right" }}>
              <Button
                as="a"
                style={{ color: "#a0fcfa" }}
                href={`https://etherscan.io/address/${detail.contract}`}
                target="_blank"
              >
                Contract
              </Button>
            </div>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

import React, { useEffect } from "react";

import AddLiquidity from "./components/AddLiquidity";
import RemoveLiquidity from "./components/RemoveLiquidity";
import Tabs from "components/CustomTabs/Tabs.js";
import { useConnectWallet } from "features/home/redux/hooks";
import { useFetchPools } from "features/liquidity/redux/hooks";
import moment from "moment";

export default function LiquidityPage() {
  const { web3, address } = useConnectWallet();
  const { fetchPools } = useFetchPools();
  useEffect(() => {
    if (web3 && address) {
      fetchPools();
      const id = setInterval(() => {
        fetchPools();
      }, 10000);
      return () => clearInterval(id);
    }
  }, [web3, address]);

  return (
    <>
    
      <h1 className="header">Liquidity</h1>
      <h3 style={{ color: "red" }}>
        Notice : Adding liquidity through MGG site (YES, Here) won't charge any
        transfer fee.
      </h3>
      <div>
        <Tabs
          tabs={[
            {
              label: "Deposit",
              content: <AddLiquidity />,
            },
            {
              label: "Remove",
              content: <RemoveLiquidity />,
            },
          ]}
        />
      </div>
    </>
  );
}

import React, { useEffect, useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useConnectWallet } from "features/home/redux/hooks";
import _ from "lodash";
import Button from "components/CustomButtons/Button.js";
const useStyles = makeStyles((theme) => ({
  card: {
    flexGrow: 1,
    backgroundColor: "rgba(0,0,0,0.5)",
    overflow: "hidden",
    borderRadius: 5,
    marginTop: 30,
    padding: 30,
    lineHeight: '2em'
  },
  title: {
    marginTop: 50
  }
}));

export default function About() {
  const classes = useStyles();
  const theme = useTheme();
  const { web3, address } = useConnectWallet();

  return (
    <>
    <div className={classes.card}>
      <h1>About</h1>
      MGG is a derivative of <a target="_blank" href="https://mud.xyz">mud.xyz</a>, we are not part of the team.
      <h2 className={classes.title}>Who are we?</h2>
      MGG combines NFT + DeFi, taking yield farming to game economies. At the sametime adding value into these metaverses by developing the content and tokenomic.
      Both Loot and Mud.Xyz are great starting point, but we found mud.xyz to have much more depth and stronger communities.
      <h2 className={classes.title}>Who can claim MGG Token?</h2>
      Every HOE NFT holder is eligible to claim MGG Token.
      <h2 className={classes.title}>What's MGG Token?</h2>
      MGG Token represent the DAO in MGG. MGG Token holders will be able to :
      <ul>
        <li>Bid for guild</li>
        <li>Buy in-game items</li>
        <li>Staking - Earn Guild Tax (Transfer fee), farm $MGG</li>
        <li>Governance Vote</li>
        <li>More to come<img src="https://a.slack-edge.com/production-standard-emoji-assets/13.0/apple-medium/1f440.png"/></li>
      </ul>
      Tokenomics
      Dynamic fixed supply
      Distribution:
      <ul>
        <li>HOE holders: 80%</li>
        <li>Pool 2 farming: 10%</li>
        <li>Ecosystem fund to grow mud.xyz: 5%</li>
        <li>Dev. team: 5%</li>
      </ul>

      <h2 className={classes.title}>What's the MGG Token distribution schedule?</h2>
      1HOE = 1,040 MGG , Distributed in 52 weeks.

      <h2 className={classes.title}>What's Guild Tax (Transfer fee)?</h2>
      Guild tax is set at 25% in the beginning, which will be changed in the future via governance vote.
      <ul>
      <li>
      12.5% is added into MGG:USDC LP
      </li>
      <li>
      12.5% is rewarded to MGG staking pool
      </li>
      </ul>

      <h2 className={classes.title}>If I transfer my HOE to another address, can I still claim MGG ?</h2>
      No. Only the HOE holding address is eligible to claim MGG.<br/><br/>

      <b>Important Note:</b> If a HOE's MGG Token distribution batch 1 (Week1) is claimed, and you transferred it to another address, then the address WILL NOT be able to claim the batch 1 MGG Token since it's already be claimed by the previous address (holder).
    </div>
    <div className={classes.card}>
    <h1>Roadmap</h1>
    <b>Design & develop items, characters, cards for HOE</b>
    <ul>
    <li> MGG token launch</li>
<li> MGG grant program </li>
<li> MGG governance vote</li>
<li> MGG token utilities </li>
<li> MGG guild game launches (create your own guild)</li>
<li> HOE adventurer stats + images</li>
<li> 1st card game based on HOE</li>
<li> More to reveal soon !!</li>
    </ul>
    </div>
    </>
  );
}

import React, { useEffect, useState } from "react";
import Button from "components/CustomButtons/Button.js";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import _ from "lodash";
import moment from "moment";
import { Link } from "react-router-dom";
import BigNumber from "bignumber.js";
import CustomTable from "components/CustomTable/CustomTable.js";
import { useConnectWallet } from "features/home/redux/hooks";
import { useFetchClaimAll } from "features/claim/redux/hooks";
import { contracts, tokens, uniRouterABI } from "features/configure";
import { convertAmountFromNumber } from "features/helpers/bignumber";

const useStyles = makeStyles((theme) => ({
  card: {
    flexGrow: 1,
    backgroundColor: "rgba(0,0,0,0.5)",
    overflow: "hidden",
    borderRadius: 5,
    marginTop: 30,
    padding: 30,
  }
}));
export default function Batch({detail,currentAmount,weeklyUnclaimed}) {
  const classes = useStyles();
  const { address, web3 } = useConnectWallet();
  const { fetchClaimAll } = useFetchClaimAll();

  const onClaimAll = (week) => {
    fetchClaimAll({
      address,
      web3,
      week
    }).then(() => {
      // console.log('done')
    });
  };

  // const mockData = [
  //   { batch: 2696, numOfTokens: 5, unlockTime: moment().format(), availableToClaim: currentAmount },
  //   { batch: 2697, numOfTokens: 5, unlockTime: moment().format(), availableToClaim: 1000 },
  //   { batch: 2698, numOfTokens: 5, unlockTime: moment().format(), availableToClaim: 1000 },
  // ];

  const startWeek = detail.startWeek ? parseInt(detail.startWeek) : 0;
  const currentWeek = detail.currentWeek ? parseInt(detail.currentWeek) :0;
  const weekLength = currentWeek - startWeek;
  const hoeTokenBalance = detail.hoeTokenBalance ? detail.hoeTokenBalance.toLocaleString() : '-';
  const claimedHOE = detail.claimedTokenCount ? detail.claimedTokenCount.toLocaleString() : 0
  const claimedMGG = detail.claimedTokenCount ? (detail.claimedTokenCount * 20).toLocaleString() : 0
  const unclaimedHOE = detail.hoeTokenBalance && detail.claimedTokenCount >= 0 ? (detail.hoeTokenBalance-detail.claimedTokenCount).toLocaleString() : 0;
  const unclaimedMGG = unclaimedHOE > 0 ? unclaimedHOE * 20 : 0;


  let inputData = [];
  for(let i=0; i<=weekLength; i++) {
    let weekInTimestamp = (startWeek+i)*604800*1000
    inputData.push({
      batch: startWeek+i,
      numOfTokens: hoeTokenBalance,
      unlockTime: moment(weekInTimestamp).format(),
      availableToClaim: weeklyUnclaimed[i]?weeklyUnclaimed[i]['false']*20 :0
    })
  }

  return (
    <div>
      { inputData.map((data) => {
        return (
          <div className={classes.card} style={{ padding: 30 }} key={data.batch}>
            <CustomTable
              style={{border: 0}}
              leftText={{}}
              headers={[
                "Batch",
                "No. of HOE(s)",
                "",
                "Available to claim",
                "",
              ]}
              contents={[[
                    <span style={{fontSize: 40}}>{data.batch - startWeek + 1}</span>,
                    <span style={{fontSize: 40}}>{data.numOfTokens.toLocaleString()}</span>,
                    <div style={{marginTop: -50}}>
                      <span>Batch unlock time: {data.unlockTime}</span>
                      <p>Claimed HOE: {data.availableToClaim?(hoeTokenBalance - data.availableToClaim/20):hoeTokenBalance}</p>
                      <p>Claimed MGG: {data.availableToClaim?(hoeTokenBalance*20 - data.availableToClaim):hoeTokenBalance*20}</p>
                      <p>Unclaimed HOE: {data.availableToClaim?data.availableToClaim/20:0}</p>
                      <p>Unclaimed MGG: {data.availableToClaim?data.availableToClaim:0}</p>
                    </div>,
                    <span style={{fontSize: 40}} className="coloredText">{data.availableToClaim?data.availableToClaim:0}</span>,
                    <div>
                      <div>
                        <Button color="primary" style={{fontWeight: 'lighter', border:0, color: 'black', backgroundColor: '#5ffffc' }} disabled={detail.currentWeek < data.batch }><Link to="claim/byhoe" style={{color: 'black'}}>Claim separately</Link></Button>
                      </div>
                      <div>
                        <Button color="primary" style={{fontWeight: 'lighter', border:0, color: 'black', backgroundColor: '#5fffc1' }} disabled={detail.currentWeek < data.batch || !data.availableToClaim} onClick={() => onClaimAll(data.batch)}>Claim all</Button>
                      </div>
                    </div>
              ]]}
            />
          </div>
        )
      })}
    </div>
  );
}

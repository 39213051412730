import { useCallback } from "react";
import { erc20ABI, contracts, tokens } from "features/configure";
import { useDispatch, useSelector } from "react-redux";
import {
  FETCH_TOKEN_APPROVAL_BEGIN,
  FETCH_TOKEN_APPROVAL_SUCCESS,
  FETCH_TOKEN_APPROVAL_FAILURE,
} from "./constants";
import { enqueueSnackbar } from "features/common/redux/actions";
import { approval } from "features/web3";
export function fetchApproval({
  web3,
  address,
  tokenAddress,
  contractAddress,
}) {
  return (dispatch, getState) => {
    // optionally you can have getState as the second argument
    dispatch({
      type: FETCH_TOKEN_APPROVAL_BEGIN,
    });

    const promise = new Promise(async (resolve, reject) => {
      const { home } = getState();
      const { address, web3 } = home;
      const stakingPoolAddress = contractAddress;
      try {
        await approval({
          web3,
          address,
          tokenAddress,
          contractAddress: stakingPoolAddress,
          dispatch,
        });
        dispatch({
          type: FETCH_TOKEN_APPROVAL_SUCCESS,
        });
        resolve(true);
      } catch (err) {
        dispatch({
          type: FETCH_TOKEN_APPROVAL_FAILURE,
        });
        reject(err);
      }
    });
    return promise;
  };
}

export function useFetchApproval(poolId) {
  // args: false value or array
  // if array, means args passed to the action creator
  const dispatch = useDispatch();

  const { fetchApprovalPending } = useSelector((state) => ({
    fetchApprovalPending: state.stake.fetchApprovalPending,
  }));

  const boundAction = useCallback(
    (data) => dispatch(fetchApproval(data)),
    [dispatch]
  );

  return {
    fetchApproval: boundAction,
    fetchApprovalPending,
  };
}

export function reducer(state, action) {
  const { fetchApprovalPending } = state;
  switch (action.type) {
    case FETCH_TOKEN_APPROVAL_BEGIN:
      // Just after a request is sent
      fetchApprovalPending = true;
      return {
        ...state,
        fetchApprovalPending,
      };

    case FETCH_TOKEN_APPROVAL_SUCCESS:
      // The request is success
      fetchApprovalPending = false;
      return {
        ...state,
        fetchApprovalPending,
      };

    case FETCH_TOKEN_APPROVAL_FAILURE:
      // The request is failed
      fetchApprovalPending = false;
      return {
        ...state,
        fetchApprovalPending,
      };

    default:
      return state;
  }
}

import _ from "lodash";
import { useCallback } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {
  POOLS_FETCH_BEGIN,
  POOLS_FETCH_SUCCESS,
  POOLS_FETCH_FAILURE,
} from "./constants";
import { MultiCall } from "eth-multicall";
import {
  erc20ABI,
  erc721ABI,
  liquidityHandlerABI,
  MGGTokenABI,
  tokens,
  contracts,
} from "../../configure";
import BigNumber from "bignumber.js";
import { convertAmountFromRawNumber } from "../../helpers/bignumber";

async function getTokenIds({ web3, address }) {
  const promise = new Promise(async (resolve, reject) => {
    try {
      const contract = new web3.eth.Contract(
        erc721ABI,
        contracts.NFT.HOE.address
      );
      const owner = address;
      let tokenIndexCalls = [];
      let balanceOf = await contract.methods.balanceOf(owner).call();

      if (_.isNaN(balanceOf)) {
        return reject();
      }

      for (let i = 0; i < balanceOf; i++) {
        tokenIndexCalls.push(
          contract.methods.tokenOfOwnerByIndex(owner, i).call()
        );
      }
      let tokenIndexResults = await Promise.all(tokenIndexCalls);

      tokenIndexResults = _.map(tokenIndexResults, parseFloat);
      resolve(tokenIndexResults);
    } catch (e) {
      reject(e.message || e);
    }
  });
  return promise;
}

async function checkTokenClaimStatus(web3, week, tokenIds) {
  const promise = new Promise(async (resolve, reject) => {
    const multicall = new MultiCall(web3, contracts.multicall.address);
    const mggTokenBoxContract = new web3.eth.Contract(
      MGGTokenABI,
      contracts.MGGBOX.address
    );

    const calls = [];
    for (let tokenId of tokenIds) {
      calls.push({
        result: mggTokenBoxContract.methods.getClaimStatus(week, tokenId),
      });
    }

    let results = await multicall.all([calls]);
    results = results[0];
    resolve(results);
  });

  return promise;
}

export function fetchPools() {
  return (dispatch, getState) => {
    dispatch({
      type: POOLS_FETCH_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      const { home, price } = getState();
      const { address, web3 } = home;

      const multicall = new MultiCall(web3, contracts.multicall.address);

      const hoeContract = new web3.eth.Contract(
        erc20ABI,
        contracts.NFT.HOE.address
      );
      const mggTokenBoxContract = new web3.eth.Contract(
        MGGTokenABI,
        contracts.MGGBOX.address
      );

      const calls = [
        { result: hoeContract.methods.balanceOf(address) }, //0
        { result: mggTokenBoxContract.methods.currentWeek() }, //1
        { result: mggTokenBoxContract.methods.startWeek() }, //2

      ];

      multicall
        .all([calls])
        .then(async ([results]) => {
          const hoeTokenBalance = results[0].result ? parseInt(results[0].result) : 0;
          const currentWeek = results[1].result ? parseInt(results[1].result) : 0;
          const startWeek = results[2].result ? parseInt(results[2].result) : 0;


          let weekLength = currentWeek - startWeek;

          let tokenIds = await getTokenIds({ web3, address });

          let claimedTokenStatus = [];

          for (var i = 0; i < weekLength+1; i++) {
            claimedTokenStatus.push(
              await checkTokenClaimStatus(
                web3,
                startWeek+i,
                tokenIds
              )
            )
          }

          let claimedTokens = _.filter(claimedTokenStatus, { result: true });

          const output = {
            startWeek,
            hoeTokenBalance,
            currentWeek,
            claimedTokenCount: claimedTokens.length,
            claimedTokenStatus,
          };
          dispatch({
            type: POOLS_FETCH_SUCCESS,
            data: output,
          });

          resolve();
        })
        .catch((error) => {
          dispatch({
            type: POOLS_FETCH_FAILURE,
          });
          return reject(error.message || error);
        });
    });

    return promise;
  };
}

export function useFetchPools() {
  const dispatch = useDispatch();

  const { detail, fetchPoolsPending, fetchPoolsDone } = useSelector(
    (state) => ({
      detail: state.claim.detail,
      fetchPoolsPending: state.claim.fetchPoolsPending,
      fetchPoolsDone: state.claim.fetchPoolsDone,
    }),
    shallowEqual
  );

  const boundAction = useCallback(
    (data) => {
      return dispatch(fetchPools(data));
    },
    [dispatch]
  );

  return {
    detail,
    fetchPools: boundAction,
    fetchPoolsDone,
    fetchPoolsPending,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case POOLS_FETCH_BEGIN:
      return {
        ...state,
        fetchPoolsPending: true,
      };

    case POOLS_FETCH_SUCCESS:
      return {
        ...state,
        detail: action.data,
        fetchPoolsDone: true,
        fetchPoolsPending: false,
      };

    case POOLS_FETCH_FAILURE:
      return {
        ...state,
        fetchPoolsPending: false,
      };

    default:
      return state;
  }
}

export const DO_BID_BEGIN = "DO_BID_BEGIN";
export const DO_BID_SUCCESS = "DO_BID_SUCCESS";
export const DO_BID_FAILURE = "DO_BID_FAILURE";

export const FETCH_BID_DETAIL_BEGIN = "FETCH_BID_DETAIL_BEGIN";
export const FETCH_BID_DETAIL_SUCCESS = "FETCH_BID_DETAIL_SUCCESS";
export const FETCH_BID_DETAIL_FAILURE = "FETCH_BID_DETAIL_FAILURE";

export const DO_CLOSE_AUCTION_BEGIN = "DO_CLOSE_AUCTION_BEGIN";
export const DO_CLOSE_AUCTION_SUCCESS = "DO_CLOSE_AUCTION_SUCCESS";
export const DO_CLOSE_AUCTION_FAILURE = "DO_CLOSE_AUCTION_FAILURE";


import { liquidityHandlerABI, contracts } from "../configure";
import { enqueueSnackbar } from "../common/redux/actions";

export const addLiquidity = async ({
  web3,
  address,
  tokenBalance,
  pairBalance,
  dispatch,
}) => {
  const contract = new web3.eth.Contract(
    liquidityHandlerABI,
    contracts.liquidityHandler.address
  );
  const data = await _addLiquidity({
    web3,
    contract,
    tokenBalance,
    pairBalance,
    address,
    dispatch,
  });
  return data;
};

const _addLiquidity = ({
  web3,
  contract,
  tokenBalance,
  pairBalance,
  address,
  dispatch,
}) => {
  return new Promise((resolve, reject) => {
    contract.methods
      .addLiquidity(
        tokenBalance,
        pairBalance,
        0,
        0,
        address,
        new Date().getTime() + 8000
      )
      .send({ from: address })
      .on("transactionHash", function (hash) {
        console.log(hash);
        dispatch(
          enqueueSnackbar({
            message: hash,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: "success",
            },
            hash,
          })
        );
      })
      .on("receipt", function (receipt) {
        console.log(receipt);
        resolve();
      })
      .on("error", function (error) {
        console.log(error);
        reject(error);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

import { HomePage, StakingPoolPage } from "./";
import { QuestPage, GamePage } from "features/game";
export default {
  path: "",
  childRoutes: [
    { path: "index", component: HomePage, isIndex: true },
    { path: "stake", component: StakingPoolPage, isIndex: false },
    { path: "game", component: GamePage, isIndex: false },
    { path: "quest", component: QuestPage, isIndex: false },
  ],
};

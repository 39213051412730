import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import history from "./history";
import homeReducer from "features/home/redux/reducer";
import commonReducer from "features/common/redux/reducer";
import infoReducer from "features/info/redux/reducer";
import stakeReducer from "features/stake/redux/reducer";
import liquidityReducer from "features/liquidity/redux/reducer";
import claimReducer from "features/claim/redux/reducer";
import bidReducer from "features/bid/redux/reducer";
import { reducer as priceReducer } from "../components/Price/fetchPrice";

// NOTE 1: DO NOT CHANGE the 'reducerMap' name and the declaration pattern.
// This is used for Rekit cmds to register new features, remove features, etc.
// NOTE 2: always use the camel case of the feature folder name as the store branch name
// So that it's easy for others to understand it and Rekit could manage them.

const reducerMap = {
  router: connectRouter(history),
  home: homeReducer,
  common: commonReducer,
  price: priceReducer,
  dashboard: infoReducer,
  stake: stakeReducer,
  claim: claimReducer,
  liquidity: liquidityReducer,
  bid: bidReducer,
};

export default combineReducers(reducerMap);

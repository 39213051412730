export const POOLS_FETCH_CLAIM_BEGIN = "POOLS_FETCH_CLAIM_BEGIN";
export const POOLS_FETCH_CLAIM_SUCCESS = "POOLS_FETCH_CLAIM_SUCCESS";
export const POOLS_FETCH_CLAIM_FAILURE = "POOLS_FETCH_CLAIM_FAILURE";

export const POOLS_FETCH_CLAIM_ALL_BEGIN = "POOLS_FETCH_CLAIM_ALL_BEGIN";
export const POOLS_FETCH_CLAIM_ALL_SUCCESS = "POOLS_FETCH_CLAIM_ALL_SUCCESS";
export const POOLS_FETCH_CLAIM_ALL_FAILURE = "POOLS_FETCH_CLAIM_ALL_FAILURE";

export const POOLS_FETCH_CLAIM_SINGLE_BEGIN = "POOLS_FETCH_CLAIM_SINGLE_BEGIN";
export const POOLS_FETCH_CLAIM_SINGLE_SUCCESS = "POOLS_FETCH_CLAIM_SINGLE_SUCCESS";
export const POOLS_FETCH_CLAIM_SINGLE_FAILURE = "POOLS_FETCH_CLAIM_SINGLE_FAILURE";
export const POOLS_FETCH_BEGIN = "POOLS_FETCH_BEGIN";
export const POOLS_FETCH_SUCCESS = "POOLS_FETCH_SUCCESS";
export const POOLS_FETCH_FAILURE = "POOLS_FETCH_FAILURE";

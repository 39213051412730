import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  POOLS_FETCH_CLAIM_BEGIN,
  POOLS_FETCH_CLAIM_SUCCESS,
  POOLS_FETCH_CLAIM_FAILURE,
} from "./constants";
import { poolClaim } from "../../web3";
import { fetchPools } from "features/stake/redux/fetchPools";


export function fetchClaim({ address, web3, pid }) {
  return (dispatch) => {
    dispatch({
      type: POOLS_FETCH_CLAIM_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      poolClaim({ web3, address, pid, dispatch })
        .then((data) => {
          dispatch({
            type: POOLS_FETCH_CLAIM_SUCCESS,
            data,
          });
          fetchPools();
          resolve(data);
        })
        .catch((error) => {
          dispatch({
            type: POOLS_FETCH_CLAIM_FAILURE,
          });
          reject(error.message || error);
        });
    });
    return promise;
  };
}

export function useFetchClaim() {
  const dispatch = useDispatch();

  const { fetchClaimPending } = useSelector((state) => ({
    fetchClaimPending: state.stake.fetchClaimPending,
  }));

  const boundAction = useCallback(
    (data) => {
      return dispatch(fetchClaim(data));
    },
    [dispatch]
  );

  return {
    fetchClaim: boundAction,
    fetchClaimPending,
  };
}

export function reducer(state, action) {
  let { fetchClaimPending } = state;
  switch (action.type) {
    case POOLS_FETCH_CLAIM_BEGIN:
      fetchClaimPending = true;
      return {
        ...state,
        fetchClaimPending,
      };

    case POOLS_FETCH_CLAIM_SUCCESS:
      fetchClaimPending = false;
      return {
        ...state,
        fetchClaimPending,
      };

    case POOLS_FETCH_CLAIM_FAILURE:
      fetchClaimPending = false;
      return {
        ...state,
        fetchClaimPending,
      };

    default:
      return state;
  }
}

import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  POOLS_FETCH_STAKE_BEGIN,
  POOLS_FETCH_STAKE_SUCCESS,
  POOLS_FETCH_STAKE_FAILURE,
} from "./constants";
import { poolDeposit } from "../../web3";
import { fetchPools } from "features/stake/redux/fetchPools";


export function fetchStake({ address, web3, pid, amount }) {
  return (dispatch) => {
    dispatch({
      type: POOLS_FETCH_STAKE_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      poolDeposit({ web3, address, pid, amount, dispatch })
        .then((data) => {
          dispatch({
            type: POOLS_FETCH_STAKE_SUCCESS,
            data,
          });
          fetchPools();
          resolve(data);
        })
        .catch((error) => {
          dispatch({
            type: POOLS_FETCH_STAKE_FAILURE,
          });
          reject(error.message || error);
        });
    });
    return promise;
  };
}

export function useFetchStake() {
  const dispatch = useDispatch();

  const { fetchStakePending } = useSelector((state) => ({
    fetchStakePending: state.stake.fetchStakePending,
  }));

  const boundAction = useCallback(
    (data) => {
      return dispatch(fetchStake(data));
    },
    [dispatch]
  );

  return {
    fetchStake: boundAction,
    fetchStakePending,
  };
}

export function reducer(state, action) {
  let { fetchStakePending } = state;
  switch (action.type) {
    case POOLS_FETCH_STAKE_BEGIN:
      fetchStakePending = true;
      return {
        ...state,
        fetchStakePending,
      };

    case POOLS_FETCH_STAKE_SUCCESS:
      fetchStakePending = false;
      return {
        ...state,
        fetchStakePending,
      };

    case POOLS_FETCH_STAKE_FAILURE:
      fetchStakePending = false;
      return {
        ...state,
        fetchStakePending,
      };

    default:
      return state;
  }
}

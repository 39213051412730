import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import axios from 'axios';
import {
  FETCH_DASHBOARD_BEGIN,
  FETCH_DASHBOARD_SUCCESS,
  FETCH_DASHBOARD_FAILURE,
} from './constants';
// import { apiUrl } from "features/configure"
import { MultiCall } from "eth-multicall";
import { erc721ABI, contracts, tokens } from 'features/configure';

function parseIPFS_URI(uri) {
  let uriSplit = uri.split('ipfs://')
  if(uriSplit.length > 1) {
    return uriSplit[1];
  }
  else {
    return uri;
  }
}

export function fetchNFT({ web3, address }) {
  return dispatch => {
    dispatch({
      type: FETCH_DASHBOARD_BEGIN,
    });

    const promise = new Promise(async(resolve, reject) => {
      try {
        const contract = new web3.eth.Contract(erc721ABI, contracts.NFT.HOE.address);
        // const owner = address;
        const multicall = new MultiCall(web3, contracts.multicall.address);
        const owner = address; //TODO: get address from wallet

        let tokenIndexCalls = [];
        let tokenURICalls = [];
        let ipfsCalls = [];
        let ipfsImagesCid = [];
        let ipfsAttrs = [];

        let balanceOf = await contract.methods.balanceOf(owner).call();

        if(_.isNaN(balanceOf)) {
          return reject();
        }

        for(let i=0; i<balanceOf; i++) {
          tokenIndexCalls.push({ result: contract.methods.tokenOfOwnerByIndex(owner, i) });
        }


        let tokenIndexResults = (await multicall.all([tokenIndexCalls]))[0];


        for(let tokenIndex of tokenIndexResults) {
          tokenURICalls.push({ result: contract.methods.tokenURI(tokenIndex.result) })
        }

        let tokenURIResults = (await multicall.all([tokenURICalls]))[0];


        for(let tokenURI of tokenURIResults) {
          let cid = parseIPFS_URI(tokenURI.result)
          ipfsCalls.push(axios.get(`https://ipfs.infura.io:5001/api/v0/cat?arg=${cid}`))
        }

        let ipfsResults = await Promise.all(ipfsCalls);

        for(let ipfsData of ipfsResults) {
          let ipfsImgUrl = _.get(ipfsData.data, 'image');
          let attrs = _.get(ipfsData.data, 'attributes');
          ipfsAttrs.push(attrs);
          let cid = parseIPFS_URI(ipfsImgUrl)
          ipfsImagesCid.push(cid)
        }

        let data = _.map(tokenIndexResults, (tokenId, i) => {
          return {
            tokenId: tokenId.result,
            imgCid: ipfsImagesCid[i],
            attrs: ipfsAttrs[i]
          }
        })

        // console.log(data)

        dispatch({
          type: FETCH_DASHBOARD_SUCCESS,
          data,
        });
        resolve(data)
      }
      catch(e) {
        dispatch({
          type: FETCH_DASHBOARD_FAILURE,
        });
        reject(e.message || e);
      }
    });
    return promise;
  };
}

export function useFetchNFT() {
  // args: false value or array
  // if array, means args passed to the action creator
  const dispatch = useDispatch();
  const { nftsData, fetchNFTPending } = useSelector(
    state => ({
      nftsData: state.dashboard.nftsData,
      fetchNFTPending: state.dashboard.fetchNFTPending,
    })
  );
  const boundAction = useCallback(
    data => {
      return dispatch(fetchNFT(data));
    },
    [dispatch]
  );
  return {
    nftsData,
    fetchNFT: boundAction,
    fetchNFTPending
  };
}

export function reducer(state, action) {
  let { fetchNFTPending, NFTData } = state;
  switch (action.type) {
    case FETCH_DASHBOARD_BEGIN:
      fetchNFTPending = true;
      return {
        ...state,
        fetchNFTPending,
      };

    case FETCH_DASHBOARD_SUCCESS:

      return {
        ...state,
        nftsData: action.data,
        fetchNFTPending: false
      };

    case FETCH_DASHBOARD_FAILURE:
      // The request is failed
      fetchNFTPending = false;

      return {
        ...state,
        nftsData: undefined,
        fetchNFTPending
      };

    default:
      return state;
  }
}

import React from "react";
import _ from "lodash";
import { Grid, Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import NumberFormat from "react-number-format";
import TextField from "@material-ui/core/TextField";
import Button from "components/CustomButtons/Button";
const useStyles = makeStyles((theme) => ({
  field: {
    marginTop: 20,
    padding: 15,
    borderRadius: 10,
    backgroundColor: "transparent",
  },
  showDetail: {
    alignItems: "center",
    marginBottom: 10,
    justifyContent: "space-around",
    borderRadius: 10,
    width: "100%",
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.paper,
    fontWeight: 600,
    "& fieldset": {},
    "& .MuiInputBase-root.Mui-disabled": {
      backgroundColor: "transparent",
      color: theme.palette.text.primary,
      border: `none`,
      "& fieldset": {
        border: `none`,
      },
    },
    outline: "none",
  },
  hovered: {
    "& fieldset": {},
  },
  focused: {
    "& fieldset": {},
  },
}));

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      style={{ fontSize: "24px", padding: 20 }}
      getInputRef={inputRef}
      allowLeadingZeros={false}
      allowNegative={false}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      isNumericString
    />
  );
}

export default function CustomOutlinedInput(props) {
  const commonStyle = useStyles();
  const commonClasses = {
    root: commonStyle.showDetail,
  };

  const { classes, disabled, setMax, text, footerText, availabletext, token, placeholder } =
    props;
  const filteredProps = _.omit(props, ["setMax", "startAdornment"]);

  if (token) {
    return (
      <div className={commonStyle.field}>
        <Grid container>
          <Grid item xs={3}>
            {" "}
            <img
              style={{ marginRight: 5 }}
              className="lgIcon"
              src={require("assets/img/" + token.token + ".png")}
            />
            <span className="vaultHeader">{token.token}</span>
          </Grid>
          <Grid item xs={9}>
            <TextField
              {...filteredProps}
              placeholder={placeholder || "0"}
              classes={Object.assign({}, commonClasses, classes)}
              InputProps={{
                inputComponent: NumberFormatCustom,

                disableUnderline: true,
                endAdornment: (
                  <InputAdornment
                    position="end"
                    className={commonStyle.inputAdornment}
                  >
                    {setMax && (
                      <Button
                        onClick={setMax}
                        color="smPrimary"
                        size="sm"
                        disabled={disabled}
                      >
                        MAX
                      </Button>
                    )}
                    {availabletext && (
                      <span
                        style={{
                          minWidth: 200,
                          fontWeight: 600,
                          textAlign: "right",
                          paddingRight: 10,
                        }}
                      >
                        {availabletext}
                      </span>
                    )}
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
      </div>
    );
  } else {
    return (
      <div className={commonStyle.field}>
        <div style={{ paddingBottom: 10 }}>
          <span className="valueHeader">{text}</span>
          <span style={{ float: "right", fontWeight: 600 }}>
            {availabletext}
          </span>
        </div>
        <TextField
          {...filteredProps}
          placeholder={"0"}
          classes={Object.assign({}, commonClasses, classes)}
          InputProps={{
            inputComponent: NumberFormatCustom,
            disableUnderline: true,
            endAdornment: (
              <InputAdornment
                position="end"
                className={commonStyle.inputAdornment}
              >
                <Button
                  onClick={setMax}
                  color="smPrimary"
                  size="sm"
                  disabled={disabled}
                >
                  MAX
                </Button>
              </InputAdornment>
            ),
          }}
        />
        <div style={{ paddingBottom: 10 }}>
          <span style={{ fontWeight: 600 }}>
            {footerText}
          </span>
        </div>
      </div>
    );
  }
}

CustomOutlinedInput.defaultProps = {
  fullWidth: true,
};

import { useCallback } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {
  LIQUIDITY_FETCH_BEGIN,
  LIQUIDITY_FETCH_SUCCESS,
  LIQUIDITY_FETCH_FAILURE,
} from "./constants";
import { MultiCall } from "eth-multicall";
import {
  erc20ABI,
  liquidityHandlerABI,
  tokens,
  contracts,
} from "../../configure";
import BigNumber from "bignumber.js";
import { convertAmountFromRawNumber } from "../../helpers/bignumber";

export function fetchPools() {
  return (dispatch, getState) => {
    dispatch({
      type: LIQUIDITY_FETCH_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      const { home, price } = getState();
      const { address, web3 } = home;
      const { mggPrice } = price.priceData;

      const multicall = new MultiCall(web3, contracts.multicall.address);

      const stakingPoolContract = new web3.eth.Contract(
        liquidityHandlerABI,
        contracts.liquidityHandler.address
      );
      const mudContract = new web3.eth.Contract(erc20ABI, tokens.mgg.address);
      const usdcContract = new web3.eth.Contract(erc20ABI, tokens.usdc.address);
      const slpContract = new web3.eth.Contract(erc20ABI, tokens.slp.address);
      const calls = [
        { result: mudContract.methods.balanceOf(address) }, //0
        { result: usdcContract.methods.balanceOf(address) }, //1
        { result: slpContract.methods.balanceOf(address) }, //2
        {
          result: mudContract.methods.allowance(
            address,
            contracts.liquidityHandler.address
          ),
        }, //3
        {
          result: usdcContract.methods.allowance(
            address,
            contracts.liquidityHandler.address
          ),
        }, //4
        {
          result: slpContract.methods.allowance(
            address,
            contracts.liquidityHandler.address
          ),
        }, //5
        { result: slpContract.methods.totalSupply() }, //2
      ];

      multicall
        .all([calls])
        .then(([results]) => {
          const tokenBalance = convertAmountFromRawNumber(results[0].result);
          const usdcBalance = convertAmountFromRawNumber(results[1].result, 6);
          const slpBalance = convertAmountFromRawNumber(results[2].result);
          const tokenAllowance = convertAmountFromRawNumber(results[3].result);
          const usdcAllowance = convertAmountFromRawNumber(results[4].result);
          const slpAllowance = convertAmountFromRawNumber(results[5].result);
          const slpTotalSupply = convertAmountFromRawNumber(results[6].result);
          const output = {
            tokenBalance,
            usdcBalance,
            tokenAllowance,
            slpBalance,
            usdcAllowance,
            slpAllowance,
            slpTotalSupply,
          };
          console.log(output);
          dispatch({
            type: LIQUIDITY_FETCH_SUCCESS,
            data: output,
          });
          resolve();
        })
        .catch((error) => {
          dispatch({
            type: LIQUIDITY_FETCH_FAILURE,
          });
          return reject(error.message || error);
        });
    });

    return promise;
  };
}

export function useFetchPools() {
  const dispatch = useDispatch();

  const { liquidityDetail, fetchPoolsPending, fetchPoolsDone } = useSelector(
    (state) => ({
      liquidityDetail: state.liquidity.liquidityDetail,
      fetchPoolsPending: state.liquidity.fetchPoolsPending,
      fetchPoolsDone: state.liquidity.fetchPoolsDone,
    }),
    shallowEqual
  );

  const boundAction = useCallback(
    (data) => {
      return dispatch(fetchPools(data));
    },
    [dispatch]
  );

  return {
    liquidityDetail,
    fetchPools: boundAction,
    fetchPoolsDone,
    fetchPoolsPending,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case LIQUIDITY_FETCH_BEGIN:
      return {
        ...state,
        fetchPoolsPending: true,
      };

    case LIQUIDITY_FETCH_SUCCESS:
      return {
        ...state,
        liquidityDetail: action.data,
        fetchPoolsDone: true,
        fetchPoolsPending: false,
      };

    case LIQUIDITY_FETCH_FAILURE:
      return {
        ...state,
        fetchPoolsPending: false,
      };

    default:
      return state;
  }
}

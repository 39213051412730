import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DO_CLOSE_AUCTION_BEGIN, DO_CLOSE_AUCTION_SUCCESS, DO_CLOSE_AUCTION_FAILURE } from "./constants";
import { doCloseAuction } from "features/web3";
import { fetchBidDetail } from "features/bid/redux/fetchBidDetail";

export function fetchDoCloseAuction({ address, web3}) {
  return (dispatch) => {
    dispatch({
      type: DO_CLOSE_AUCTION_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      doCloseAuction({ web3, address, dispatch })
        .then((data) => {
          dispatch({
            type: DO_CLOSE_AUCTION_SUCCESS,
            data,
          });
          fetchBidDetail();
          resolve(data);
        })
        .catch((error) => {
          dispatch({
            type: DO_CLOSE_AUCTION_FAILURE,
          });
          reject(error.message || error);
        });
    });
    return promise;
  };
}

export function useFetchDoCloseAuction() {
  const dispatch = useDispatch();

  const { fetchDoCloseAuctionPending } = useSelector((state) => ({
    fetchDoCloseAuctionPending: state.bid.fetchDoCloseAuctionPending,
  }));

  const boundAction = useCallback(
    (data) => {
      return dispatch(fetchDoCloseAuction(data));
    },
    [dispatch]
  );

  return {
    fetchDoCloseAuction: boundAction,
    fetchDoCloseAuctionPending,
  };
}

export function reducer(state, action) {
  let { fetchDoCloseAuctionPending } = state;
  switch (action.type) {
    case DO_CLOSE_AUCTION_BEGIN:
      fetchDoCloseAuctionPending = true;
      return {
        ...state,
        fetchDoCloseAuctionPending,
      };

    case DO_CLOSE_AUCTION_SUCCESS:
      fetchDoCloseAuctionPending = false;
      return {
        ...state,
        fetchDoCloseAuctionPending,
      };

    case DO_CLOSE_AUCTION_FAILURE:
      fetchDoCloseAuctionPending = false;
      return {
        ...state,
        fetchDoCloseAuctionPending,
      };

    default:
      return state;
  }
}

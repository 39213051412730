import React from "react";
import styled from "styled-components";
import { ThemeProvider, StylesProvider } from "@material-ui/core/styles";
import twitterIcon from "../../assets/img/social/twitter.svg";
// import gitbookIcon from "../../assets/img/social/gitbook.svg";
// import githubIcon from "../../assets/img/social/github.svg";
// import telegramIcon from "../../assets/img/social/telegram.svg";
import discordIcon from "../../assets/img/social/discord.svg";
import mediumIcon from "../../assets/img/social/medium.svg";
import dragon from "../../assets/img/landing/dragon.png";
import badges from "../../assets/img/landing/badges.png";
import Container from "@material-ui/core/Container";
import LandingHeader from "./components/LandingHeader";
import LandingContent from "./components/LandingContent";
import Grid from "@material-ui/core/Grid";
import createTheme from "../home/jss/appTheme";
import { makeStyles } from "@material-ui/core/styles";

const styles = theme => ({
  root: {
    backgroundColor: 'blue',
    [theme.breakpoints.up('md')]: {
      backgroundColor: 'red',
    },
  }
});

const useStyles = makeStyles((theme) => ({
  card: {
    minHeight: "330px",
    padding: 25,
    border: `2px solid ${theme.palette.text.primary}`,
    borderRadius: "5px",
    overflow: "hidden",
    position: "relative",
    textAlign: "left",
    margin: "20px auto",
    maxWidth: 275,
  },
  dragonBg: {
    objectFit: "cover",
    position: "absolute",
    left: -20,
    top: 70,
    bottom: 0,
    minHeight: "80vh",
    width: "50%",
    height: "80vh",
    opacity: 0.3,
    [theme.breakpoints.down('md')]: {
      objectFit: 'contain',
    },
  },
  badgeBg: {
    objectFit: "cover",
    position: "absolute",
    right: 10,
    top: 10,
    bottom: 0,
    minHeight: "90vh",
    width: "50%",
    opacity: 0.3,
    [theme.breakpoints.down('md')]: {
      objectFit: 'contain',
    },
  }
}));

const ImageIcon = ({ src, size = 26 }) => {
  return (
    <img src={src}
      height={size}
      style={{
        filter: 'invert(1)',
        marginTop: -4,
      }}
    />
  )
}

const Landing = () => {
  const theme = createTheme(true);
  const classes = useStyles();

  return (
    <StylesProvider injectFirst>
      <ThemeProvider theme={theme}>
        <img
          src={dragon}
          class={classes.dragonBg}
          style={{

          }}
        />
        <img
          src={badges}
          class={classes.badgeBg}
          style={{

          }}
        />
        <Container>
          <div
            style={{
              minHeight: "100vh",
              position: "relative",
              overflow: "hidden",
              backgroundColor: "transparent",
            }}
          >
            <LandingHeader />
            <LandingContent />
            <Grid
              justify="center"
              alignItems="center"
              container
              spacing={3}
              style={{ marginTop: 100 }}
            >
              <Grid item>
                <StyledNav>
                  <div style={{border: "2px solid", borderRadius: 20, padding: '12px 40px 4px 40px'}}>
                    <StyledLink target="_blank" href="https://twitter.com/MudGuildGame">
                      <ImageIcon src={twitterIcon} />
                    </StyledLink>
                    <StyledLink target="_blank" href="https://discord.gg/k3rVbCRwVU">
                      <ImageIcon src={discordIcon} />
                    </StyledLink>
                    <StyledLink href="javascript: void(0)">
                      <ImageIcon src={mediumIcon} />
                    </StyledLink>
                  </div>
                </StyledNav>
              </Grid>
            </Grid>


          </div>
        </Container>
      </ThemeProvider>
    </StylesProvider>
  );
};

const Background = styled.div`
  background-image: url(${dragon});
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: left center;
  background-position-x: -5vw;
  display: block;

  &::before: {
    opacity: 0.6;
  }
`;

const StyledText = styled.span`
  color: black;
  font-weight: 900;
`;

const Blank = styled.div`
  height: 50px;
  @media (max-width: 500px) {
    height: 30px;
  }
`;

const StyledNav = styled.nav`
  padding: 1.5rem;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const StyledLink = styled.a`
  color: white;
  padding: 16px;
  text-decoration: none;
  &:hover {
    color: #805e49;
  }
`;

export default Landing;

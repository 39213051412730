import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ButtonBase from "@material-ui/core/ButtonBase";

const useStyles = makeStyles((theme) => ({
  subTitle: {
    color: "gray",
    fontSize: 18,
    marginLeft: 20,
  },
  timeSpan: {
    color: "gray",
    fontSize: 22,
    marginLeft: 5,
    marginRight: "15px",
  },
  xlIcon: {
    height: 400,
    width: "100%",
    objectFit: "contain",
  },
  banner: {
    width: "100%",
    maxWidth: 350,
    height: 60,
    objectFit: "contain",
  },
  bidNumber: {
    margin: "30px 0",
    fontSize: 50,
  },
  imageButtonBase: {
    width: 200,
    margin: 30,
    position: "relative",
    height: 60,
  },
  imageButton: {
    position: "absolute",
    left: 0,
    top: 0,
    width: 200,
    height: 60,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  imageSrc: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: "cover",
  },

  tab: {
    width: "100%",
    position: "relative",
    maxWidth: 1000,
    padding: 20,
    margin: "10px auto",
  },
  tabBackground: {
    width: "100%",
    position: "absolute",
    objectFit: "cover",
    minHeight: 400,
    top: 0,
    left: 0,
  },
  tabTitle: {
    fontSize: 28,
    borderBottom: "1px solid white",
  },
  tabText: {
    zIndex: 100,
    padding: "70px 40px",
    maxWidth: 800,
    margin: "20px auto",
    fontSize: 18,
    position: "relative",
    textAlign: "center",
  },
}));

export default function QuestPage() {
  const classes = useStyles();
  const renderTab = (title, text, url) => {
    return (
      <div className={classes.tab}>
        <div className={classes.tabText}>
          <span className={classes.tabTitle}>{title}</span>
          <div style={{ marginTop: 20 }}>{text}</div>
          <ButtonBase
            disableRipple
            className={classes.imageButtonBase}
            onClick={() => {
              window.open(url, "_blank");
            }}
          >
            <h1 style={{ zIndex: 100, fontSize: 20 }}>Accept</h1>
            <img
              className={classes.imageButton}
              src={require("assets/img/btn3.png")}
            />
          </ButtonBase>
        </div>
        <img
          src={require("assets/img/tab.png")}
          className={classes.tabBackground}
        />
      </div>
    );
  };
  return (
    <>
      <h1 className="header">Quests</h1>
      {renderTab(
        "Chapter 1 - Let the world know MGG.",
        "Spread the word to the world about MGG.",
        "https://discord.gg/k3rVbCRwVU"
      )}
      {renderTab(
        "Chapter 2 - Summon the company.",
        "Summon HOEs to join the MGG.",
        "https://discord.gg/k3rVbCRwVU"
      )}
      {renderTab(
        "Chapter 3 - Let MGG know who you are.",
        "Show MGG your top adventurer.",
        "https://discord.gg/k3rVbCRwVU"
      )}
      {renderTab(
        "Chapter 4 - Collect resources to make your army stronger.",
        "Claim MGG tokens to enpower your army.",
        "https://discord.gg/k3rVbCRwVU"
      )}
    </>
  );
}

import initialState from "./initialState";
import { reducer as fetchStakeReducer } from "./fetchStake";
import { reducer as fetchUnstakeReducer } from "./fetchUnstake";

import { reducer as fetchPoolsReducer } from "./fetchPools";

const reducers = [fetchStakeReducer, fetchUnstakeReducer, fetchPoolsReducer];

export default function reducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    // Handle cross-topic actions here
    default:
      newState = state;
      break;
  }
  /* istanbul ignore next */
  return reducers.reduce((s, r) => r(s, action), newState);
}

import StakePage from "features/stake/StakePage";
import LiquidityPage from "features/liquidity/LiquidityPage";
import React from "react";

export default function StakingPoolPage() {
  return (
    <>
      <LiquidityPage />
      <StakePage />
    </>
  );
}

import React, { useEffect } from "react";
import { useConnectWallet } from "features/home/redux/hooks";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { useFetchNFT } from "../info/redux/fetchNFT";
import { useFetchPools } from "features/claim/redux/hooks";
import {useFetchClaimSingle} from "./redux/fetchClaimSingle";
import {
  useParams
} from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Button from "../../components/CustomButtons/Button.js";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {},
  card: {
    flexGrow: 1,
    backgroundColor: "rgba(0,0,0,0.5)",
    overflow: "hidden",
    borderRadius: 5,
    marginTop: 30,
    padding: 30,
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  title: {
    color: theme.palette.text.primary,
    textAlign: "left",
    fontSize: "1.1rem",
    paddingTop: 15,
  },
  subTitle: {
    textAlign: "center",
    fontSize: "1.3rem",
    marginTop: 10,
    color: theme.palette.text.secondary,
  },
  metricsTitle: {
    fontSize: "0.8rem",
    marginTop: 18,
    marginBottom: 8,
    fontWeight: 600,
    color: theme.palette.text.secondary,
  },
  metricsValue: {
    fontSize: "0.9rem",
    fontWeight: 700,
    color: theme.palette.text.primary,
  },
  cardTitle: {
    fontSize: "0.85rem",
    marginBottom: 20,
    fontWeight: 600,
    color: theme.palette.text.secondary,
  },
  cardValue: {
    fontSize: "0.9rem",
    fontWeight: 700,
    marginBottom: 17,
    color: theme.palette.text.primary,
    textAlign: "left",
  },
  cardApyValue: {
    fontSize: "1rem",
    fontWeight: 700,
    color: theme.palette.text.green,
    marginBottom: 20,
    textAlign: "right",
    textDecoration: "underline",
  },
  bigGreenValue: {
    textAlign: "center",
    fontSize: "2.3rem",
    color: theme.palette.text.green,
  },
  bigValue: {
    textAlign: "center",
    fontSize: "2rem",
    color: theme.palette.text.primary,
  },
  label: {
    color: theme.palette.text.primary,
    fontFamily: "Menlo",
    fontSize: "1.15rem",
    fontWeight: "800",
  },
  lgIcon: {
    width: 150,
    height: 150,
    objectFit: "contain",
  },
  icon: {
    width: 42,
    height: 42,
    objectFit: "contain",
  },
  outerIcon: {
    width: 50,
    height: 50,
    objectFit: "contain",
  },
  outerLgIcon: {
    width: 170,
    height: 170,
    objectFit: "contain",
  },
}));

const mockData = [



  { batch: 2701, numOfTokens: 7, unlockTime: new Date().toString(), availableToClaim: 42250 },
  { batch: 2702, numOfTokens: 7, unlockTime: new Date().toString(), availableToClaim: 42250 },
  { batch: 2703, numOfTokens: 7, unlockTime: new Date().toString(), availableToClaim: 42250 },
  { batch: 2704, numOfTokens: 7, unlockTime: new Date().toString(), availableToClaim: 42250 },
  { batch: 2705, numOfTokens: 7, unlockTime: new Date().toString(), availableToClaim: 42250 }
];

export default function ClaimByHOEPage() {
  const { web3, address } = useConnectWallet();
  const { nftsData, fetchNFT } = useFetchNFT();
  const { fetchClaimSingle } = useFetchClaimSingle();
  const { detail, fetchPools } = useFetchPools();
  const classes = useStyles();

  useEffect(() => {
    if (address) {
      fetchPools();
      fetchNFT({ web3, address });
      const id = setInterval(() => {
        fetchPools();
        fetchNFT({ web3, address });
      }, 30000);
      return () => clearInterval(id);
    }
  }, [address]);

  const hoeTokenBalance = detail.hoeTokenBalance ? detail.hoeTokenBalance.toLocaleString() : '-';
  const availableToClaim = detail.hoeTokenBalance && detail.claimedTokenCount >= 0 ? ((detail.hoeTokenBalance-detail.claimedTokenCount) * 20).toLocaleString() : '-';
  const expectedToClaim = hoeTokenBalance >0 ? (hoeTokenBalance * 20 * 52).toLocaleString() : '-'

  const renderButtons = (data, index, detail) => {
    let weekLength = detail.currentWeek - detail.startWeek;

    let buttons = [];

    for (var i = 0; i < weekLength+1; i++) {
      let weekNumber = detail.startWeek+i;
      buttons.push(<Button style={{border:'solid 1px white'}} onClick={()=>{
        fetchClaimSingle({ web3, address, tokenId:data.tokenId, week:weekNumber});
      }} disabled={detail.claimedTokenStatus[i][index]['result']}>{detail.claimedTokenStatus[i][index]['result']?'Claimed':`Week${i+1}`}</Button>)
    }



    return buttons;
  }

  const renderNFTCollections = () => {
    if (!nftsData) {
      return <div>Loading...</div>;
    } else if (nftsData.length == 0) {
      return <div>No Items</div>;
    }
    return nftsData.map((data, index) => {
      return (
        <Grid item xs={12} md={12} key={index}>
          <div className={classes.card} style={{ padding: 30 }}>
            <Grid container spacing={3} alignItems="center">
              <Grid item xs={12} md={4}>
                <div>
                  {index + 1}
                  <span style={{ float: "right" }}>#{data.tokenId}</span>
                </div>
                <img
                  src={"https://ipfs.infura.io/ipfs/" + data.imgCid}
                  style={{
                    width: "100%",
                    marginTop: 10,
                    objectFit: "contain",
                    border: "1px solid white",
                  }}
                  alt=""
                />
              </Grid>
              <Grid>
              {renderButtons(data, index, detail)}




              </Grid>

            </Grid>
          </div>
        </Grid>
      );
    });
  };

  let utcTime = "2021-09-11 02:30:00";
  var local_date= moment.utc(utcTime ).local().format('YYYY-MM-DD HH:mm:ss');

  return (
    <>
      <h1 className="header">Claim By HOE</h1>
      <div className={classes.card}>
        <div>Claim your weekly MGG DAO Token</div>
        <div>You have: {hoeTokenBalance} HOE(s)</div>
        <div>Expeteced to claim: {expectedToClaim} MGG Token (In 52 weeks)</div>
        <div>Available to claim: {availableToClaim} MGG Token</div>
        <div> - </div>
        <div>Next unlock batch time: - </div>
        <div>No. of next batch: - </div>
      </div>
      <div>
        {renderNFTCollections()}
      </div>
    </>
  );
}

import initialState from "./initialState";
import { reducer as fetchStakeReducer } from "./fetchStake";
import { reducer as fetchUnstakeReducer } from "./fetchUnstake";
import { reducer as fetchClaimReducer } from "./fetchClaim";
import { reducer as fetchStakeMGGReducer } from "./fetchStakeMGG";
import { reducer as fetchClaimStakeMGGReducer } from "./fetchClaimStakeMGG";
import { reducer as fetchUnstakeMGGReducer } from "./fetchUnstakeMGG";
import { reducer as fetchPoolsReducer } from "./fetchPools";

const reducers = [
  fetchStakeReducer,
  fetchUnstakeReducer,
  fetchClaimReducer,
  fetchPoolsReducer,
  fetchStakeMGGReducer,
  fetchUnstakeMGGReducer,
  fetchClaimStakeMGGReducer
];

export default function reducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    // Handle cross-topic actions here
    default:
      newState = state;
      break;
  }
  /* istanbul ignore next */
  return reducers.reduce((s, r) => r(s, action), newState);
}

export const contracts = {
  NFT: {
    HOE: {
      address: "0xf1eF40f5aEa5D1501C1B8BCD216CF305764fca40",
    },
  },
  MGGBOX: {
    address: "0xF64eeBB9aBA493A0F61D2c8c536e60027EF97cB8",
  },
  multicall: {
    address: "0x5Eb3fa2DFECdDe21C950813C665E9364fa609bD2",
  },
  liquidityHandler: {
    address: "0xae2422a280b945180aaC72d8e6E424c8F19E0419",
  },
  stakingPools: {
    address: "0x36430A7771a7072f0Da6d5c7E347E79Da0c67b8f",
  },
  mggStaking: {
    address: "0x4dDC0C08A6d2724389051E60B2DB761BF7417D52",
  },

  uniRouter: {
    address: "0xd9e1cE17f2641f24aE83637ab66a2cca9C378B9F",
  },
  mggTokenBox: {
    address: "0xF64eeBB9aBA493A0F61D2c8c536e60027EF97cB8",
  },
  auctionHouse: {
    address: "0xc351628EB244ec633d5f21fBD6621e1a683B1181",
  },
  nounsAuctionHouse: {
    address: "0x830bd73e4184cef73443c15111a1df14e495c706"
  }
};

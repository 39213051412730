import React, { useEffect, useState } from "react";
import Button from "components/CustomButtons/Button.js";
import Tabs from "components/CustomTabs/Tabs.js";
import { Grid, Container } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import _ from "lodash";
import { useParams, Link } from "react-router-dom";
import BigNumber from "bignumber.js";
import CustomOutlinedInput from "components/CustomOutlinedInput/CustomOutlinedInput";
import { inputLimitPass, inputFinalVal } from "features/helpers/utils";
import { useConnectWallet } from "features/home/redux/hooks";
import { contracts, tokens, uniRouterABI } from "features/configure";
import { convertAmountFromNumber } from "features/helpers/bignumber";
import { useFetchPrice } from "components/Price/fetchPrice";
import {
  useFetchPools,
  useFetchApproval,
  useFetchStake,
} from "features/liquidity/redux/hooks";

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: "1rem",
    fontWeight: 1000,
    marginTop: 20,
    marginBottom: 30,
    color: theme.palette.text.secondary,
  },
  subTitle: {
    fontSize: "0.9rem",
    marginBottom: 20,
  },
  textTitle: {
    textAlign: "center",
    fontWeight: 900,
    paddingTop: 20,
    fontSize: "0.9rem",
    color: theme.palette.text.secondary,
  },
  textValue: {
    textAlign: "center",
    fontWeight: 900,
    padding: 20,
    fontSize: "1.1rem",
    color: theme.palette.text.primary,
  },
  card: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${theme.palette.text.primary}`,
    overflow: "hidden",
    borderRadius: 5,
    marginTop: 30,
    padding: 20,
  },
  labelLink: {
    color: theme.palette.text.primary,
    fontSize: 16,
    fontWeight: 600,
    textDecoration: "underline",
  },
}));
export default function AddLiquidity(props) {
  const classes = useStyles();
  const theme = useTheme();
  const { priceData } = useFetchPrice();
  const price = priceData.mggPrice;
  const { address, web3 } = useConnectWallet();
  const [tokenBalance, setTokenBalance] = useState("");
  const [pairBalance, setPairBalance] = useState("");
  const { liquidityDetail } = useFetchPools();
  const { fetchApproval } = useFetchApproval();
  const { fetchStake, fetchStakePending } = useFetchStake();
  const decimals = 18;
  const isInit = false;
  const detail = liquidityDetail;
  console.log(detail);
  const onStake = () => {
    fetchStake({
      address,
      web3,
      tokenBalance: convertAmountFromNumber(tokenBalance),
      pairBalance: convertAmountFromNumber(pairBalance, 6),
    }).then(() => {
      setTokenBalance("");
      setPairBalance("");
    });
  };

  const updatePairAmount = (value, isToken) => {
    if (isInit) return;
    if (isToken) {
      if (value) {
        setPairBalance(value * price);
      }
    } else {
      if (value) {
        setTokenBalance(value / price);
      }
    }
  };

  const setTokenBalanceMaximumInput = () => {
    const total = detail.tokenBalance;
    if (total > 0) {
      const amount = new BigNumber(total).toFormat().replaceAll(",", "");
      setTokenBalance(amount);
      updatePairAmount(amount, true);
    }
  };
  const setPairBalanceMaximumInput = () => {
    const total = detail.usdcBalance;
    if (total > 0) {
      const amount = new BigNumber(total).toFormat().replaceAll(",", "");
      setPairBalance(amount);
      updatePairAmount(amount, false);
    }
  };
  const changeTokenInputValue = (event, decimals = 18) => {
    let value = event.target.value;
    const total = detail.wallet;
    if (!inputLimitPass(value, decimals)) {
      return;
    }
    const input = inputFinalVal(value, total, decimals);
    setTokenBalance(input);
    updatePairAmount(input, true);
  };
  const changePairInputValue = (event, decimals = 6) => {
    let value = event.target.value;
    const total = detail.available;
    if (!inputLimitPass(value, decimals)) {
      return;
    }
    const input = inputFinalVal(value, total, decimals);
    setPairBalance(input);
    updatePairAmount(input, false);
  };
  const onApproval = (tokenAddress) => {
    fetchApproval({ tokenAddress });
  };
  const isMGGApproved =
    detail.tokenAllowance && parseFloat(detail.tokenAllowance) > 0;
  const isUSDCApproved =
    detail.usdcAllowance && parseFloat(detail.usdcAllowance) > 0;
  return (
    <>
      <div>
        <div style={{ maxWidth: 800, margin: "0 auto" }}>
          <div style={{ padding: 0 }}>
            <CustomOutlinedInput
              value={tokenBalance}
              text={"Add"}
              availabletext={`Available: ${parseFloat(
                detail.tokenBalance
              ).toFixed(8)}`}
              token={tokens.mgg}
              onChange={changeTokenInputValue}
              setMax={() => setTokenBalanceMaximumInput()}
            />
            <div style={{textAlign:'right'}}><Button as="a" style={{color:'#a0fcfa'}} href="https://etherscan.io/address/0xB435EEffE949182AeBe85506EB88D4a0fFeA8f9E" target="_blank">MGG Contract</Button></div>
            <div style={{ fontSize: 40, textAlign: "center" }}>+</div>
            <CustomOutlinedInput
              value={pairBalance}
              text={"Add"}
              availabletext={`Available: ${parseFloat(
                detail.usdcBalance
              ).toFixed(2)}`}
              token={tokens.usdc}
              onChange={changePairInputValue}
              setMax={() => setPairBalanceMaximumInput()}
            />
          </div>
          <div style={{textAlign:'right'}}><Button style={{color:'#a0fcfa'}} as="a" href="https://app.sushi.com/swap?inputCurrency=0xdac17f958d2ee523a2206206994597c13d831ec7&outputCurrency=0xB435EEffE949182AeBe85506EB88D4a0fFeA8f9E" target="_blank">Buy MGG</Button><Button as="a" style={{color:'#a0fcfa'}} href="https://etherscan.io/address/0xc73eE6632B08D890E67aA30e667B55cFDF281D96" target="_blank">SLP Contract</Button></div>

          <div style={{ marginTop: 30, textAlign: "center"}}>
            {!isMGGApproved ? (
              <Button
                color="primary"
                onClick={() => onApproval(tokens.mgg.address)}
              >
                Approve MGG
              </Button>
            ) : !isUSDCApproved ? (
              <Button
                color="primary"
                onClick={() => onApproval(tokens.usdc.address)}
              >
                Approve USDT
              </Button>
            ) : (
              <Button
                color="primary"
                onClick={() => onStake()}
                disabled={
                  !tokenBalance ||
                  !pairBalance ||
                  fetchStakePending ||
                  parseFloat(tokenBalance) > parseFloat(detail.tokenBalance) ||
                  parseFloat(pairBalance) > parseFloat(detail.usdcBalance)
                }
              >
                Add Liquidity
              </Button>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

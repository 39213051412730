import React, { useEffect, useState } from "react";
import { ThemeProvider, StylesProvider } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
// import Header from 'components/Header/Header';
import Header from "features/landing/components/LandingHeader";
import { useTranslation } from "react-i18next";
import Toolbar from "@material-ui/core/Toolbar";
import { Notifier } from "features/common";
import { useConnectWallet } from "./redux/hooks";
import appStyle from "./jss/appStyle.js";
import createTheme from "./jss/appTheme";
import DashboardBg from "assets/img/bg/bg_10.jpg";
import AboutBg from "assets/img/bg/bg_03.jpg";
import BG1 from "assets/img/bg/bg_1.jpg";
// import BG3 from "assets/img/bg/bg_3.jpg";
import BG4 from "assets/img/bg/bg_4.jpg";
import BG5 from "assets/img/bg/bg_5.jpg";
// import BG10 from "assets/img/bg/bg_10.jpg";

const useStyles = makeStyles(appStyle);

export default function App({ children }) {
  const classes = useStyles();
  const { networkId } = useConnectWallet();
  const { t } = useTranslation();

  let background = DashboardBg

  if(window.location.hash == '#/app/about') {
    background = AboutBg
  }
  else if(window.location.hash == '#/app') {
    background = BG1
  }
  else if(window.location.hash == '#/app/claim' || window.location.hash == '#/app/claim/byhoe') {
    background = BG5
  }
  else if(window.location.hash == '#/app/stake') {
    background = BG4
  }
  else {
    background = DashboardBg
  }
  // const { isNightMode, setNightMode } = useNightMode();
  const theme = createTheme(true);

  return (
    <StylesProvider injectFirst>
      <ThemeProvider theme={theme}>
        <div
          style={{
            backgroundImage: `url(${background})`,
            backgroundPosition: "left bottom",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundAttachment: "fixed",
          }}
        >
          <div style={{ maxWidth: 1200, margin: "0 auto" }}>
            <Header />
            <main className={classes.container}>
              <Toolbar />
              <div className={classes.children}>
                {Boolean(
                  networkId === Number(process.env.REACT_APP_NETWORK_ID)
                ) && children}
                <Notifier />
              </div>
            </main>
          </div>
        </div>
      </ThemeProvider>
    </StylesProvider>
  );
}

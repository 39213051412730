import React, { memo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { useFetchPrice } from "./fetchPrice";

import styles from "./styles";
import { useConnectWallet } from "features/home/redux/connectWallet";

const useStyles = makeStyles(styles);

const Price = () => {
  const classes = useStyles();
  const { web3 } = useConnectWallet();

  const { fetchPrice, priceData } = useFetchPrice();

  useEffect(() => {
    if (web3) {
      fetchPrice({ web3 });

      const id = setInterval(() => {
        fetchPrice({ web3 });
      }, 10000);
      return () => clearInterval(id);
    }
  }, [web3]);

  let currentPrice = priceData.price ? priceData.price.toFixed(3) : "-";

  return (
    <span style={{ textAlign: "center" }}>
      <img
        src={require("assets/img/MGG.png")}
        style={{ width: 20, height: 20 }}
      ></img>
      ${currentPrice}
    </span>
  );
};

export default memo(Price);

import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ButtonBase from "@material-ui/core/ButtonBase";

const useStyles = makeStyles((theme) => ({
  subTitle: {
    color: "gray",
    fontSize: 18,
    marginLeft: 20,
  },
  timeSpan: {
    color: "gray",
    fontSize: 22,
    marginLeft: 5,
    marginRight: "15px",
  },
  xlIcon: {
    height: 400,
    width: "100%",
    objectFit: "contain",
  },
  banner: {
    width: "100%",
    maxWidth: 350,
    height: 60,
    objectFit: "contain",
  },
  bidNumber: {
    margin: "30px 0",
    fontSize: 50,
  },
  imageButtonBase: {
    width: 200,
    margin: 30,
    position: "relative",
    height: 70,
  },
  imageButton: {
    position: "absolute",
    left: 0,
    top: 0,
    width: 200,
    height: 70,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  imageSrc: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: "cover",
  },
  screen: {
    height: 400,
    width: "100%",
    maxWidth: 600,
    objectFit: "contain",
    margin: "20px auto",
  },
  tab: {
    width: "100%",
    position: "relative",
    maxWidth: 900,
    minHeight: 400,
    padding: 20,
    margin: "10px auto",
  },
  tabBackground: {
    width: "100%",
    position: "absolute",
    top: 0,
    left: 0,
  },
  tabTitle: {
    fontSize: 28,
    borderBottom: "1px solid white",
  },
  tabText: {
    zIndex: 100,
    padding: 40,
    maxWidth: 600,
    margin: "20px auto",
    fontSize: 18,
    position: "relative",
    textAlign: "center",
  },
}));

export default function GamePage() {
  const classes = useStyles();

  return (
    <>
      <h1 className="header">Game</h1>
      <div className="card">
        <h2>MGG DAO</h2>
        <h2> - use $MGG to vote for the game.</h2>
      </div>
      <div style={{ textAlign: "center" }}>
        <img
          src={require("assets/img/gameScreen.png")}
          className={classes.screen}
        />
      </div>
      <div className={classes.tab}>
        <div className={classes.tabText}>
          <span className={classes.tabTitle}>Dungeon Slayer</span>
          <div style={{ marginTop: 20 }}>
            Use your wisdom to kill evil slime to earn the treasure.
          </div>
          <ButtonBase
            disableRipple
            className={classes.imageButtonBase}
            onClick={() => {
              window.open("https://gameone.mudguild.com/", "_blank");
            }}
          >
            <h1 style={{ zIndex: 100, fontSize: 20 }}>Play</h1>
            <img
              className={classes.imageButton}
              src={require("assets/img/btn3.png")}
            />
          </ButtonBase>
        </div>
        <img
          src={require("assets/img/tab.png")}
          className={classes.tabBackground}
        />
      </div>
    </>
  );
}

import React, { useEffect, useState } from "react";
import Button from "components/CustomButtons/Button.js";
import Tabs from "components/CustomTabs/Tabs.js";
import { Grid, Modal } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import _ from "lodash";
import { useParams, Link } from "react-router-dom";
import BigNumber from "bignumber.js";
import CustomOutlinedInput from "components/CustomOutlinedInput/CustomOutlinedInput";
import { inputLimitPass, inputFinalVal } from "features/helpers/utils";
import { useConnectWallet } from "features/home/redux/hooks";
import { tokens } from "features/configure";
import { useFetchPrice } from "components/Price/fetchPrice";
import {
  useFetchPools,
  useFetchUnstake,
  useFetchApproval,
} from "features/liquidity/redux/hooks";
import { convertAmountFromNumber } from "features/helpers/bignumber";

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: "1rem",
    fontWeight: 1000,
    marginTop: 20,
    marginBottom: 30,
    color: theme.palette.text.secondary,
  },
  subTitle: {
    fontSize: "0.9rem",
    marginBottom: 20,
  },
  textTitle: {
    textAlign: "center",
    fontWeight: 900,
    paddingTop: 20,
    fontSize: "0.9rem",
    color: theme.palette.text.secondary,
  },
  textValue: {
    textAlign: "center",
    fontWeight: 900,
    padding: 20,
    fontSize: "1.1rem",
    color: theme.palette.text.primary,
  },
  card: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${theme.palette.text.primary}`,
    overflow: "hidden",
    borderRadius: 5,
    marginTop: 30,
    padding: 20,
  },
  labelLink: {
    color: theme.palette.text.primary,
    fontSize: 16,
    fontWeight: 600,
    textDecoration: "underline",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    width: "30%",
    minWidth: 360,
    textAlign: "center",
    backgroundColor: "black",
    padding: "15px 25px",
  },
}));
export default function RemoveLiquidity(props) {
  const classes = useStyles();
  const theme = useTheme();
  const { priceData } = useFetchPrice();
  const price = priceData.price;
  const lpPrice = priceData.lpPrice;
  const { address, web3 } = useConnectWallet();
  const [tokenBalance, setTokenBalance] = useState("");
  const [pairBalance, setPairBalance] = useState(["", ""]);
  const { liquidityDetail } = useFetchPools();
  const { fetchApproval } = useFetchApproval();
  const { fetchUnstake, fetchUnstakePending } = useFetchUnstake();
  const decimals = 18;
  const [open, setOpen] = useState(false);
  const detail = liquidityDetail;

  const handleClose = () => {
    setOpen(false);
  };

  const onUnstake = (amount) => {
    let amountValue = amount ? amount.replaceAll(",", "") : amount;
    const formated = convertAmountFromNumber(amountValue);

    fetchUnstake({
      address,
      web3,
      amount: formated,
    }).then(() => {
      setTokenBalance("");
      setPairBalance(["", ""]);
      setOpen(false);
    });
  };
  const updatePairAmount = (value) => {
    if (value) {
      setPairBalance([(value * lpPrice) / (2 * price), (value * lpPrice) / 2]);
    }
  };

  const setTokenBalanceMaximumInput = () => {
    const total = detail.slpBalance;
    if (total > 0) {
      const amount = new BigNumber(total).toFormat().replaceAll(",", "");
      setTokenBalance(amount);
      updatePairAmount(amount);
    }
  };

  const changeTokenInputValue = (event) => {
    let value = event.target.value;
    const total = detail.slpBalance;
    if (!inputLimitPass(value, decimals)) {
      return;
    }
    const input = inputFinalVal(value, total, decimals);
    setTokenBalance(input);
    updatePairAmount(input);
  };
  const onApproval = (tokenAddress) => {
    fetchApproval({ tokenAddress });
  };

  const isSLPApproved =
    detail.slpAllowance && parseFloat(detail.slpAllowance) > 0;
  return (
    <div>
      <Modal open={open} onClose={handleClose} className={classes.modal}>
        <div className={classes.paper}>
          <h2>Removing MGG-USDT LP </h2>
          <div style={{ marginTop: 30, fontSize: 18 }}>
            You will receive
            <br />
            <Grid container alignItems="center">
              <Grid
                item
                xs={8}
                className="vaultHeader"
                style={{ verticalAlign: "middle" }}
              >
                {pairBalance[0]}
              </Grid>
              <Grid item xs={4}>
                <img
                  style={{ marginRight: 5 }}
                  className="lgIcon"
                  src={require("assets/img/MGG.png")}
                />
                <span>MGG</span>
              </Grid>
              <Grid
                item
                xs={8}
                className="vaultHeader"
                style={{ verticalAlign: "middle" }}
              >
                {pairBalance[0]}
              </Grid>
              <Grid item xs={4}>
                <img
                  style={{ marginRight: 5 }}
                  className="lgIcon"
                  src={require("assets/img/USDT.png")}
                />
                <span>USDT</span>
              </Grid>
            </Grid>
          </div>
          <div style={{ maginTop: 50 }}>1 MGG = {price} USDT</div>
          <Button
            color="primary"
            onClick={() => onUnstake(tokenBalance)}
            disabled={
              !tokenBalance ||
              fetchUnstakePending ||
              parseFloat(tokenBalance) > parseFloat(detail.slpBalance)
            }
          >
            Remove
          </Button>
        </div>
      </Modal>

      <div style={{ maxWidth: 500, margin: "0 auto" }}>
        <div
          style={{
            fontWeight: 700,
            fontSize: 30,
            margin: "20px 0",
            textAlign: "center",
          }}
        >
          <img
            style={{ marginRight: 5 }}
            className="lgIcon"
            src={require("assets/img/SLP.png")}
          />
          <span>MGG-USDT LP</span>
        </div>
        <div style={{ padding: 0 }}>
          <CustomOutlinedInput
            value={tokenBalance}
            availabletext={`Available: ${parseFloat(detail.slpBalance).toFixed(
              8
            )}`}
            onChange={changeTokenInputValue}
            setMax={() => setTokenBalanceMaximumInput()}
          />
        </div>
        <div style={{ marginTop: 30, textAlign: "center" }}>
          {isSLPApproved ? (
            <Button
              color="primary"
              onClick={() => {
                setOpen(true);
              }}
              disabled={
                !tokenBalance ||
                fetchUnstakePending ||
                parseFloat(tokenBalance) > parseFloat(detail.slpBalance)
              }
            >
              Remove Liquidity
            </Button>
          ) : (
            <Button
              color="primary"
              onClick={() => onApproval(tokens.slp.address)}
            >
              Approve SLP
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}

import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  MGG_FETCH_STAKE_BEGIN,
  MGG_FETCH_STAKE_SUCCESS,
  MGG_FETCH_STAKE_FAILURE,
} from "./constants";
import { stakeMGG } from "../../web3";
import { fetchPools } from "features/stake/redux/fetchPools";
export function fetchStakeMGG({ address, web3, amount }) {
  return (dispatch) => {
    dispatch({
      type: MGG_FETCH_STAKE_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      stakeMGG({ web3, address, amount, dispatch })
        .then((data) => {
          dispatch({
            type: MGG_FETCH_STAKE_SUCCESS,
            data,
          });
          fetchPools();
          resolve(data);
        })
        .catch((error) => {
          dispatch({
            type: MGG_FETCH_STAKE_FAILURE,
          });
          reject(error.message || error);
        });
    });
    return promise;
  };
}

export function useFetchStakeMGG() {
  const dispatch = useDispatch();

  const { fetchStakeMGGPending } = useSelector((state) => ({
    fetchStakeMGGPending: state.stake.fetchStakeMGGPending,
  }));

  const boundAction = useCallback(
    (data) => {
      return dispatch(fetchStakeMGG(data));
    },
    [dispatch]
  );

  return {
    fetchStakeMGG: boundAction,
    fetchStakeMGGPending,
  };
}

export function reducer(state, action) {
  let { fetchStakeMGGPending } = state;
  switch (action.type) {
    case MGG_FETCH_STAKE_BEGIN:
      fetchStakeMGGPending = true;
      return {
        ...state,
        fetchStakeMGGPending,
      };

    case MGG_FETCH_STAKE_SUCCESS:
      fetchStakeMGGPending = false;
      return {
        ...state,
        fetchStakeMGGPending,
      };

    case MGG_FETCH_STAKE_FAILURE:
      fetchStakeMGGPending = false;
      return {
        ...state,
        fetchStakeMGGPending,
      };

    default:
      return state;
  }
}

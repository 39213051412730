export const tokens = {
  mgg: {
    token: "MGG",
    address: "0xB435EEffE949182AeBe85506EB88D4a0fFeA8f9E",
    decimals: 18,
  },
  usdc: {
    token: "USDT",
    address: "0xdac17f958d2ee523a2206206994597c13d831ec7",
    decimals: 6,
  },
  slp: {
    token: "SLP",
    address: "0xc73eE6632B08D890E67aA30e667B55cFDF281D96",
    decimals: 18,
  },
  guild: {
    token: "MGGDEV",
    address: "0x7bc06c482DEAd17c0e297aFbC32f6e63d3846650",
    decimals: 18
  }
};

import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DO_BID_BEGIN, DO_BID_SUCCESS, DO_BID_FAILURE } from "./constants";
import { doBid } from "features/web3";
import { fetchBidDetail } from "features/bid/redux/fetchBidDetail";

export function fetchDoBid({ address, web3, tokenId, amount }) {
  return (dispatch) => {
    dispatch({
      type: DO_BID_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      doBid({ web3, address, tokenId, amount, dispatch })
        .then((data) => {
          dispatch({
            type: DO_BID_SUCCESS,
            data,
          });
          fetchBidDetail();
          resolve(data);
        })
        .catch((error) => {
          dispatch({
            type: DO_BID_FAILURE,
          });
          reject(error.message || error);
        });
    });
    return promise;
  };
}

export function useFetchDoBid() {
  const dispatch = useDispatch();

  const { fetchDoBidPending } = useSelector((state) => ({
    fetchDoBidPending: state.bid.fetchDoBidPending,
  }));

  const boundAction = useCallback(
    (data) => {
      return dispatch(fetchDoBid(data));
    },
    [dispatch]
  );

  return {
    fetchDoBid: boundAction,
    fetchDoBidPending,
  };
}

export function reducer(state, action) {
  let { fetchDoBidPending } = state;
  switch (action.type) {
    case DO_BID_BEGIN:
      fetchDoBidPending = true;
      return {
        ...state,
        fetchDoBidPending,
      };

    case DO_BID_SUCCESS:
      fetchDoBidPending = false;
      return {
        ...state,
        fetchDoBidPending,
      };

    case DO_BID_FAILURE:
      fetchDoBidPending = false;
      return {
        ...state,
        fetchDoBidPending,
      };

    default:
      return state;
  }
}

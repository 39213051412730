import React from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import Button from "components/CustomButtons/Button.js";

const useStyles = makeStyles((theme) => ({
  card: {
    minHeight: "330px",
    padding: 25,
    border: `2px solid ${theme.palette.text.primary}`,
    borderRadius: "5px",
    overflow: "hidden",
    position: "relative",
    textAlign: "left",
    margin: "20px auto",
    maxWidth: 275,
  },
  auditCard: {
    border: `1px solid ${theme.palette.text.primary}`,
    borderRadius: "5px",
    overflow: "hidden",
    position: "relative",
    textAlign: "center",
  },
  title: {
    fontSize: 40,
    fontWeight: 800,
    color: theme.palette.text.primary,
  },
  subTitle: {
    fontSize: 20,
    color: theme.palette.text.primary,
    margin: "30px 0",
  },
  lendingTitle: {
    fontSize: 18,
    fontWeight: 800,
    color: theme.palette.text.primary,
    margin: "5px 0",
  },
  cardTitle: {
    fontSize: 18,
    fontWeight: 800,
    marginBottom: 10,
    color: theme.palette.text.primary,
  },
  description: {
    marginTop: 10,
    fontSize: 13,
    lineHeight: 1.8,
    color: theme.palette.text.secondary,
  },
  dot: {
    color: theme.palette.text.green,
  },
  hr: {
    border: `0.5px solid ${theme.palette.text.green}`,
    maxWidth: 600,
    margin: "40px auto",
  },
  fullHr: {
    border: `0.5px solid ${theme.palette.text.green}`,
    margin: "35px auto",
  },
  cardButton: {
    position: "absolute",
    bottom: 0,
    left: 0,
    width: "100%",
    padding: 20,
  },
}));

const LandingContent = () => {
  const classes = useStyles();

  return (
    <div
      style={{
        margin: "10px auto",
        marginTop: "30vh",
        textAlign: "center",
        maxWidth: 900,
      }}
    >
      <div className={classes.title}>{"Welcome To MUD Guild Game"}</div>
      <Link to="/app">
        <Button
          variant="outlined"
          style={{
            marginTop: 50,
            width: 300,
            height: 45,
            borderColor: "white",
            borderRadius: 20,
          }}
        >
          Claim Your MGG Token
        </Button>
      </Link>
      <div className={classes.subTitle}>{`Get ready to play the first "player-to-earn" game based on mud.xyz.`}</div>
    </div>
  );
};

export default LandingContent;

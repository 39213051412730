import { useCallback } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {
  FETCH_BID_DETAIL_BEGIN,
  FETCH_BID_DETAIL_SUCCESS,
  FETCH_BID_DETAIL_FAILURE,
} from "./constants";
import { MultiCall } from "eth-multicall";
import {
  erc20ABI,
  liquidityHandlerABI,
  AuctionHouseABI,
  NounsAuctionHouseABI,
  NFTABI,
  tokens,
  contracts,
} from "../../configure";
import BigNumber from "bignumber.js";
import { convertAmountFromRawNumber } from "../../helpers/bignumber";
import axios from "axios";
import _ from "lodash";

// const SUBGRAPH_API = `https://api.thegraph.com/subgraphs/name/evan2701/mud-guild-game`
const SUBGRAPH_API = `https://api.thegraph.com/subgraphs/name/nounsdao/nouns-subgraph` //TODO

async function querySubgraph(query) {
  return new Promise(async(resolve, reject) => {
    try {
      const res = await axios.post(SUBGRAPH_API, query);
      if(res && res.data) {
        resolve(res.data)
      }
      else {
        reject('no response')
      }
    } catch(e) {
      reject(e)
    }
  });
}

async function getBidsByTokenId(tokenId) { //TODO
  let subgraphQuery = `{
      bids(where:{auction: \"${tokenId}\"},orderBy: blockTimestamp, orderDirection: desc) {
        id
        amount
        blockNumber
        blockTimestamp
        txIndex
        bidder {
          id
        }
        noun {
        id
        }
      }
    }
  `
  let query = {
    "query": subgraphQuery,
    "variables": null
  }

  return querySubgraph(query);
}

export function fetchBidDetail({ web3, address }) {
  return (dispatch, getState) => {
    dispatch({
      type: FETCH_BID_DETAIL_BEGIN,
    });

    const promise = new Promise(async (resolve, reject) => {
      const { home, price } = getState();
      const { address, web3 } = home;

      const multicall = new MultiCall(web3, contracts.multicall.address);
      const auctionContract = new web3.eth.Contract(AuctionHouseABI, contracts.auctionHouse.address);
      const chainBalance = await web3.eth.getBalance(address);
      const calls = [
        { result: auctionContract.methods.auction() }, //0
        { result: auctionContract.methods.minBidIncrementPercentage() }, //1
        { result: auctionContract.methods.nft() }, //2
        { result: auctionContract.methods.reservePrice() }, //3
      ];

      // const guildAddress = tokens.guild.address

      multicall
        .all([calls])
        .then(async([results]) => {
          let tokenId = results[0].result[0];
          let nftAddress = results[2].result;
          console.log('tokenId', tokenId)
          console.log('nftAddress', nftAddress)
          const nftContract = new web3.eth.Contract(NFTABI, nftAddress);
          let tokenURI = await nftContract.methods.tokenURI(tokenId).call();
          console.log('tokenURI', tokenURI)
          let imageUrl = "#";
          let attrs = []

          if(tokenURI.startsWith("https://")) {
            //parse
            let tokenURIResult = await axios.get(tokenURI);
            let ipfsImageUrl = _.get(tokenURIResult, 'data.image', "#")
            let uriSplit = ipfsImageUrl.split('ipfs://')
            if(uriSplit.length > 1) {
              uriSplit = uriSplit[1];
            }

            imageUrl = "https://ipfs.infura.io/ipfs/" + uriSplit;
            attrs = _.get(tokenURIResult, 'data.attributes', [])
          }

          let bids; // await getBidsByTokenId(tokenId); TODO

          const output = {
            wallet: parseFloat(convertAmountFromRawNumber(chainBalance)),
            auction: results[0].result,
            reservePrice: parseFloat(convertAmountFromRawNumber(results[3].result)),
            minBidIncrementPercentage: results[1].result,
            bids: bids ? bids.data.bids : [],
            imageUrl: imageUrl,
            attrs: attrs
          };
          dispatch({
            type: FETCH_BID_DETAIL_SUCCESS,
            data: output,
          });
          resolve();
        })
        .catch((error) => {
          dispatch({
            type: FETCH_BID_DETAIL_FAILURE,
          });
          return reject(error.message || error);
        });
    });

    return promise;
  };
}

export function useFetchBidDetail() {
  const dispatch = useDispatch();

  const { detail, fetchBidDetailPending } = useSelector(
    (state) => ({
      detail: state.bid.detail,
      fetchBidDetailPending: state.bid.fetchBidDetailPending,
    }),
    shallowEqual
  );

  const boundAction = useCallback(
    (data) => {
      return dispatch(fetchBidDetail(data));
    },
    [dispatch]
  );

  return {
    detail,
    fetchBidDetail: boundAction,
    fetchBidDetailPending,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case FETCH_BID_DETAIL_BEGIN:
      return {
        ...state,
        fetchBidDetailPending: true,
      };

    case FETCH_BID_DETAIL_SUCCESS:
      return {
        ...state,
        detail: action.data,
        fetchBidDetailPending: false,
      };

    case FETCH_BID_DETAIL_FAILURE:
      return {
        ...state,
        fetchBidDetailPending: false,
      };

    default:
      return state;
  }
}

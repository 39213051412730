export const POOLS_FETCH_STAKE_BEGIN = "LIQUIDITY_FETCH_STAKE_BEGIN";
export const POOLS_FETCH_STAKE_SUCCESS = "LIQUIDITY_FETCH_STAKE_SUCCESS";
export const POOLS_FETCH_STAKE_FAILURE = "LIQUIDITY_FETCH_STAKE_FAILURE";

export const POOLS_FETCH_UNSTAKE_BEGIN = "LIQUIDITY_FETCH_UNSTAKE_BEGIN";
export const POOLS_FETCH_UNSTAKE_SUCCESS = "LIQUIDITY_FETCH_UNSTAKE_SUCCESS";
export const POOLS_FETCH_UNSTAKE_FAILURE = "LIQUIDITY_FETCH_UNSTAKE_FAILURE";

export const POOLS_FETCH_CLAIM_BEGIN = "LIQUIDITY_FETCH_CLAIM_BEGIN";
export const POOLS_FETCH_CLAIM_SUCCESS = "LIQUIDITY_FETCH_CLAIM_SUCCESS";
export const POOLS_FETCH_CLAIM_FAILURE = "LIQUIDITY_FETCH_CLAIM_FAILURE";

export const LIQUIDITY_FETCH_BEGIN = "LIQUIDITY_FETCH_BEGIN";
export const LIQUIDITY_FETCH_SUCCESS = "LIQUIDITY_FETCH_SUCCESS";
export const LIQUIDITY_FETCH_FAILURE = "LIQUIDITY_FETCH_FAILURE";

export const FETCH_TOKEN_APPROVAL_BEGIN = "FETCH_TOKEN_APPROVAL_BEGIN";
export const FETCH_TOKEN_APPROVAL_SUCCESS = "FETCH_TOKEN_APPROVAL_SUCCESS";
export const FETCH_TOKEN_APPROVAL_FAILURE = "FETCH_TOKEN_APPROVAL_FAILURE";

export const FETCH_UNI_ROUTER_BEGIN = "FETCH_UNI_ROUTER_BEGIN";
export const FETCH_UNI_ROUTER_SUCCESS = "FETCH_UNI_ROUTER_SUCCESS";
export const FETCH_UNI_ROUTER_FAILURE = "FETCH_UNI_ROUTER_FAILURE";

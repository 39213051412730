import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  POOLS_FETCH_CLAIM_ALL_BEGIN,
  POOLS_FETCH_CLAIM_ALL_SUCCESS,
  POOLS_FETCH_CLAIM_ALL_FAILURE,
} from "./constants";
import { claimAll } from "features/web3";
import { fetchPools } from "features/claim/redux/fetchPools";
export function fetchClaimAll({ address, web3, week }) {
  return (dispatch) => {
    dispatch({
      type: POOLS_FETCH_CLAIM_ALL_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      claimAll({ web3, address, week, dispatch })
        .then((data) => {
          dispatch({
            type: POOLS_FETCH_CLAIM_ALL_SUCCESS,
            data,
          });
          fetchPools();
          resolve(data);
        })
        .catch((error) => {
          dispatch({
            type: POOLS_FETCH_CLAIM_ALL_FAILURE,
          });
          reject(error.message || error);
        });
    });
    return promise;
  };
}

export function useFetchClaimAll() {
  const dispatch = useDispatch();

  const { fetchClaimAllPending } = useSelector((state) => ({
    fetchClaimAllPending: state.claim.fetchClaimAllPending,
  }));

  const boundAction = useCallback(
    (data) => {
      return dispatch(fetchClaimAll(data));
    },
    [dispatch]
  );

  return {
    fetchClaimAll: boundAction,
    fetchClaimAllPending,
  };
}

export function reducer(state, action) {
  let { fetchClaimAllPending } = state;
  switch (action.type) {
    case POOLS_FETCH_CLAIM_ALL_BEGIN:
      fetchClaimAllPending = true;
      return {
        ...state,
        fetchClaimAllPending,
      };

    case POOLS_FETCH_CLAIM_ALL_SUCCESS:
      fetchClaimAllPending = false;
      return {
        ...state,
        fetchClaimAllPending,
      };

    case POOLS_FETCH_CLAIM_ALL_FAILURE:
      fetchClaimAllPending = false;
      return {
        ...state,
        fetchClaimAllPending,
      };

    default:
      return state;
  }
}

import React, { useEffect } from "react";
import Batch from "./components/Batch";
import { useConnectWallet } from "features/home/redux/hooks";
import { useFetchPools } from "features/claim/redux/hooks";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import moment from "moment";
import _ from 'lodash';

const useStyles = makeStyles((theme) => ({
  card: {
    flexGrow: 1,
    backgroundColor: "rgba(0,0,0,0.5)",
    overflow: "hidden",
    borderRadius: 5,
    marginTop: 30,
    padding: 30,
  },
}));

export default function ClaimPage() {
  const classes = useStyles();
  const { web3, address } = useConnectWallet();
  const { detail, fetchPools } = useFetchPools();

  useEffect(() => {
    if (web3 && address) {
      fetchPools();
      const id = setInterval(() => {
        fetchPools();
      }, 10000);
      return () => clearInterval(id);
    }
  }, [web3, address]);

  const hoeTokenBalance = detail.hoeTokenBalance ? detail.hoeTokenBalance.toLocaleString() : '-';
  const availableToClaim = detail.hoeTokenBalance && detail.claimedTokenCount >= 0 ? ((detail.hoeTokenBalance-detail.claimedTokenCount) * 20).toLocaleString() : '-';
  const expectedToClaim = hoeTokenBalance >0 ? (hoeTokenBalance * 20 * 52).toLocaleString() : '-'
  const currentWeek = detail.currentWeek ? parseInt(detail.currentWeek) : 0
  const nextWeekDate = currentWeek ? moment((currentWeek+1)*604800*1000).format() : '-'

  const weekUnClaimedArray = _.map(detail.claimedTokenStatus, (outter) => {
    return (_.countBy(outter, (inner) => {
      return inner.result
    }))
  });



  let utcTime = "2021-09-11 02:30:00";
  var local_date= moment.utc(utcTime ).local().format('YYYY-MM-DD HH:mm Z');

  return (
    <>

      <h1 className="header">Claim</h1>
      <div className={classes.card}>
        <div>Claim your weekly MGG DAO Token</div>
        <div>You have: {hoeTokenBalance} HOE(s)</div>
        <div>Expected to claim: {expectedToClaim} MGG Token (In 52 weeks)</div>
        <div>Available to claim: {availableToClaim} MGG Token</div>
        <div> - </div>
        <div>Next unlock batch time: {nextWeekDate} </div>
        <div>No. of next batch: - </div>
      </div>
      <div>
        <Batch detail={detail} currentAmount={availableToClaim} weeklyUnclaimed={weekUnClaimedArray} />
      </div>
    </>
  );
}
